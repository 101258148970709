.section-home-hero {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  padding-top: ac(120px, 100px);
  padding-bottom: ac(120px, 32px);

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(43, 51, 69, 0.8) 0%,
      rgba(43, 51, 69, 0.4) 100%
    );
    z-index: 1;
    opacity: .7;
  }

  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wr {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    z-index: 2;
    min-height: calc(100vh - ac(248px, 200px));

    @media (max-width: 551px) {
      justify-content: space-between;
      min-height: calc(100vh - 200px);
    }

    @media (min-width: 1600px) {
      justify-content: center;
    }

    @media (max-width: 1050px) {
      @media (min-height: 1200px) {
        justify-content: center;
      }
    }
  }

  &__title {
    color: var(--cl-white);
    margin-bottom: ac(58px, 168px);

    @media (max-width: 551px) {
      margin-left: auto;
      margin-top: 32px;
    }

    h2 {
      margin-left: ac(430px, 150px);
    }

    @media (max-width: 374px) {
      h1 {
        font-size: 40px;
      }

      h2 {
        font-size: 40px;
      }
    }
  }

  &__descr {
    color: var(--cl-white);
    margin-bottom: ac(32px, 24px);

    @media (max-width: 374px) {
      max-width: 80%;
    }
  }

  &__content {
    width: 100%;
  }

  form {
    max-width: 582px;
    width: 100%;
  }

  &__form {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

    @media (max-width: 551px) {
      flex-direction: column;
      align-items: stretch;

      .btn-search {
        width: 100%;
      }
    }

    .input-search {
      max-width: 419px;
      width: 100%;
      @mixin transparent-bg-input var(--cl-white);

      @media (max-width: 551px) {
        max-width: 100%;
      }

      
    }
  }
}
