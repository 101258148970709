.section-hero {
  position: relative;
  min-height: 71vh;
  overflow: hidden;
  padding-top: ac(120px, 100px);
  padding-bottom: ac(120px, 32px);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.expertise-page {
    min-height: 79vh;

    @media (min-width: 1900px) {
      min-height: 65vh;
    }

    .section-hero {
      &__descr {
        br {
          @media (max-width: 551px) {
            display: none;
          }
        }

        @media (max-width: 374px) {
          font-size: 30px;
        }
      }
    }
  }

  &.solutions-page {
    .section-hero {
      &__wr {
        margin-top: 8%;
      }

      &__descr {
        max-width: 80%;

        @media (max-width: 1024px) {
          max-width: 60%;
        }

        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }

  &.news-page {
    min-height: 62vh;

    .section-hero {
      &__wr {
        margin-top: 8%;
      }

      &__descr {
        color: var(--cl-light-blue-i);
      }

      &__form {
        margin-top: 8px;
        display: flex;
        align-items: center;
        gap: ac(8px, 15px);

        .input-search {
          max-width: 419px;
          width: 100%;
          border: 1px solid var(--cl-green-300);
          background: var(--cl-black);
        }

        .btn-search {
          border: 1px solid rgba(240, 251, 245, 0.5);
          background: var(--cl-green-300);

          &:hover {
            background: var(--cl-dark);
          }

          @media (max-width: 551px) {
            width: 100%;
          }
        }

        @media (max-width: 551px) {
          flex-direction: column;
          align-items: stretch;
        }
      }
    }
  }

  &.contact-us-page {
    min-height: 69vh;

    .section-hero {
      &__wr {
        margin-top: 8%;
      }

      &__descr {
        max-width: 100%;

        @media (max-width: 374px) {
          font-size: 40px;
        }
      }
    }
  }

  &.search-jobs-page {
    min-height: 63vh;
    padding-bottom: ac(0px, 32px);
    overflow: visible;
    z-index: 10;

    .section-hero {
      position: relative;

      &__decor {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__wr {
        align-items: center;
        max-width: 760px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 768px) {
          max-width: 100%;
          align-items: stretch;
        }
      }

      &__descr {
        color: var(--cl-light-blue-i);
      }

      &__form {
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 20px;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        .input-form {
          flex: 1;

          &:first-child {
            max-width: 55.8%;

            @media (max-width: 768px) {
              max-width: 100%;
            }
          }

          &:nth-child(2) {
            max-width: 42.1%;

            @media (max-width: 768px) {
              max-width: 100%;
            }
          }

          @media (max-width: 768px) {
            max-width: 100%;
          }

          input {
            min-width: 275px;
            width: 100%;
            color: var(--cl-white);
            background: var(--cl-black);
            border-color: rgba(211, 235, 242, 0.1);
          }

          .choices {
            height: 56px;
            /* position: relative !important; */

            &::after {
              background: var(--cl-green-300);
            }
          }

          .choices__list--single {
            .choices__item {
              max-width: 224px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media (max-width: 768px) {
                max-width: none;
              }
            }
          }

          .choices__placeholder {
            opacity: 0.8;
            word-break: break-word;
          }

          .choices__item {
            &:first-child {
              /* pointer-events: none; */
            }
          }

          .choices__list--dropdown,
          .choices__list[aria-expanded] {
            margin-top: -60px;
          }

          .choices__inner {
            min-width: 275px;
            color: var(--cl-white);
            background: var(--cl-black);
            border-color: rgba(211, 235, 242, 0.1);
          }

          .choices__item.is-selected {
            background: rgba(255, 255, 255, 0.1) !important;
            color: var(--cl-white);
          }

          .choices__list--dropdown {
            background: var(--cl-dark) !important;
            color: var(--cl-white);
            position: relative;

            .choices__item--selectable.is-highlighted {
              background: var(--cl-black) !important;
            }
          }
        }

        .btn-search {
          background: var(--cl-green-300);

          @media (max-width: 768px) {
            width: 100%;
          }

          &:hover {
            border-color: var(--cl-white);
            background: var(--cl-dark);
          }
        }
      }
    }
  }

  &.job-details-page {
    min-height: 63vh;
    padding-bottom: 0;
    align-items: flex-end;
    justify-content: space-between;

    .section-hero {
      overflow: hidden;

      &__decor {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__wr {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        gap: ac(60px, 30px);

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: stretch;
        }

        @media (max-width: 551px) {
          margin-top: 8%;
        }
      }

      &-left {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 24px;
        z-index: 2;
        padding-left: ac(60px, 600px, 2560, 1440);
        padding-bottom: ac(70px, 30px);

        @media (max-width: 1399px) {
          padding-left: ac(60px, 24px);
        }

        @media (max-width: 768px) {
          padding-left: 24px;
          padding-right: 24px;
          padding-bottom: 0;
        }
      }

      &-right {
        flex: 1;
        max-width: 46.67%;
        padding: ac(60px, 30px);
        border-radius: 60px 0px 0px 0px;
        border: 1px solid var(--cl-grey-i);
        background: rgba(56, 65, 84, 0.92);
        display: flex;
        flex-direction: column;
        gap: ac(14px, 10px);

        @media (max-width: 768px) {
          max-width: 100%;
          border-radius: 60px 60px 0px 0px;
        }

        &-item {
          display: flex;
          flex-direction: column;
          gap: 10px;

          &:not(:last-child) {
            padding-bottom: ac(14px, 10px);
            border-bottom: 1px solid var(--cl-grey-i);
          }

          &__title {
            color: var(--cl-green-300);
            font: 500 ac(18px, 15px)/1.36 var(--font-main) !important;
            > * {
              font: inherit !important;
            }
          }

          &__descr {
            color: var(--cl-white) !important;
            font: 500 ac(20px, 18px)/1.36 var(--font-main) !important;
            > * {
              font: inherit !important;
            }
          }
        }
      }

      &__title {
        color: var(--cl-green-50);
      }

      &__descr {
        color: var(--cl-light-blue-i);
        font-weight: 500;
      }

      &__buttons {
        display: flex;
        align-items: center;
        gap: ac(32px, 24px);
        margin-top: ac(16px, 12px);

        @media (max-width: 551px) {
          flex-direction: column;

          & > * {
            width: 100%;
          }
        }

        a.first {
          display: inline-flex;
          align-items: center;
          gap: ac(12px, 10px);
          transition: 0.3s;

          @media (max-width: 551px) {
            margin-right: auto;
            width: fit-content;
          }

          .btn-bordered-green {
            transition: 0.3s;
            background: transparent;
          }

          span {
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: var(--font-main);
            font-size: ac(16px, 14px);
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.5px;
            transition: 0.3s;
            color: var(--cl-green-50);
          }

          &:hover {
            .btn-bordered-green {
              background: var(--cl-green-300);
              color: var(--cl-green-50);
              border-color: var(--cl-green-50);
            }

            span {
              color: var(--cl-green-300);
            }
          }
        }
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(43, 51, 69, 0.8) 0%,
      rgba(43, 51, 69, 0.4) 100%
    );
    z-index: 1;
  }

  &__bg {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wr {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: 2;
  }

  &__title {
    color: var(--cl-green-300);
  }

  &__descr {
    color: var(--cl-white);
    font-weight: 300;
  }
}
