.section-image-text {
  position: relative;
  overflow: hidden;
  padding: ac(60px, 30px) 0;
  border-radius: 60px 60px 0px 0px;
  background: var(--cl-dark);

  &.solutions-page {
    .section-image-text {
      &-right {
        &__title {
          font-size: ac(44px, 30px);
          max-width: 90%;
          color: var(--cl-green-50);

          @media (max-width: 768px) {
            max-width: 100%;
          }
        }

        &__descr {
          color: var(--cl-light-blue-i);
        }
      }
    }
  }

  &.expertise-page {
    .section-image-text {
      &-right {
        &__descr {
          color: var(--cl-light-blue-i);

          .simplebar-vertical {
            right: -15px;
          }
        }
      }
    }
  }

  &__wr {
    display: flex;
    align-items: center;
    gap: ac(56px, 32px);

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-left {
    flex: 1;
    position: relative;
    z-index: 1;
    max-width: 49.09%;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(36, 165, 103, 0.3) 0%,
        rgba(36, 165, 103, 0) 100%
      );
      mix-blend-mode: color;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(43, 51, 69, 0) 60%,
        rgba(43, 51, 69, 0.4) 100%
      );
      mix-blend-mode: normal;
      z-index: 2;
    }

    &__img {
      position: relative;
      overflow: hidden;
      border: 1px solid var(--cl-black);
      border-radius: 20px;

      &::before {
        content: '';
        display: block;
        padding-top: 75%;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }
  }

  &-right {
    flex: 1;
    max-width: 42.12%;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    &__title {
      color: var(--cl-white);
      margin-bottom: ac(24px, 18px);
    }

    &__subtitle {
      color: var(--cl-green-300);
      margin-bottom: ac(18px, 16px);
      text-align: justify;
    }

    &__descr {
      color: var(--cl-white);
      margin-bottom: ac(32px, 24px);
      --fz: ac(20px, 16px);
      --lh: 1.5;
      /* --lc: 5; */
      font-size: var(--fz);
      line-height: var(--lh);
      text-align: justify;
      /* max-height: calc(var(--fz) * var(--lh) * var(--lc));

      @media (max-width: 551px) {
        --lc: 7;
      } */

      /* .simplebar-vertical {
        right: -15px;
        
        .simplebar-scrollbar::before {
          background: var(--cl-green-300);
        }
      } */
    }


    &__buttons {
      display: flex;
      align-items: center;
      gap: 12px;

      @media (max-width: 551px) {
        flex-direction: column;
        align-items: stretch;

        & > * {
          width: 100%;
        }
      }
    }
  }
}
