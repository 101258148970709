.section-contact-form {
  margin-top: -60px;
  position: relative;
  overflow: hidden;
  padding: ac(60px, 30px) 0 ac(80px, 30px);
  background: var(--cl-dark);
  border-radius: 60px 60px 0px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 10;

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: ac(32px, 24px);

    & > h2 {
      color: var(--cl-white);
      font-size: ac(40px, 30px);
    }

    &-main {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      column-gap: ac(20px, 16px);
      row-gap: ac(24px, 18px);

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .input-form {
        flex: 1;
        flex-basis: 23.6%;

        input {
          width: 100%;
        }
        
        @media (max-width: 1024px) {
          flex: 1 1 45%;
        }

        &:nth-child(3n) {
          flex-basis: ac(644px, 0px);
          margin-left: ac(12px, 8px);

          @media (max-width: 1024px) {
            margin-left: 0;
            flex: 1 1 45%;
          }

          input {
            width: 100%;
          }
        }
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 966px) {
        flex-direction: column;
        align-items: stretch;
        gap: 32px;
      }

      &__left {
        display: flex;
        align-items: center;
        gap: ac(20px, 16px);

        @media (max-width: 551px) {
          flex-direction: column;
          align-items: stretch;
        }

        .btn-dashed-green {
          user-select: none;
          cursor: pointer;
          padding: 18px 16px;
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: max-content;
            color: var(--cl-white);
            font-size: 16px;
            font-weight: 500;
            letter-spacing: .5px;
            line-height: normal;
            opacity: 1;
          }
          @media (max-width: 551px) {
            width: 100%;
          }

          input[type='file'] {
            display: none;
          }
        }

        span {
          max-width: 189px;
          opacity: 0.7;
          color: var(--Marble, #f8f6f7);
          font-family: var(--font-main);
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: 0.035px;

          @media (max-width: 551px) {
            max-width: 100%;
          }
        }
      }

      &__right {
        display: flex;
        align-items: center;
        gap: ac(64px, 24px);

        @media (max-width: 966px) {
          flex-direction: column;
        }

        .btn-green {
          min-width: 130px;
          margin-left: ac(30px, -120px);

          @media (max-width: 1024px) {
            margin-left: 0;
          }

          @media (max-width: 551px) {
            width: 100%;
          }
        } 
      }
    }
  }
}
