.section-contact-locations {
  position: relative;
  overflow: hidden;
  background: var(--cl-green-50);
  padding: ac(80px, 30px) 0;

  &__wr {
    display: flex;
    align-items: center;
    gap: ac(60px, 30px);

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }

  &-img {
    flex: 1;
    max-width: 632px;
    max-height: 633px;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--cl-green-300);
    border-radius: 32px;

    @media (max-width: 1024px) {
      width: 100%;
      max-width: 500px;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(36, 165, 103, 0.3) 0%,
          rgba(36, 165, 103, 0) 100%
        ),
        radial-gradient(
          50% 50% at 50% 50%,
          rgba(43, 51, 69, 0) 60%,
          rgba(43, 51, 69, 0.3) 100%
        );
      background-blend-mode: color, normal;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-content {
    flex: 1;

    &__title {
      color: var(--cl-black);
      margin-bottom: ac(16px, 10px);
    }

    &__descr {
      color: var(--cl-black);
      margin-bottom: ac(32px, 18px);
      max-width: 95%;

      @media (max-width: 1024px) {
        max-width: 100%;
      }
    }

    &-accordions {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .contact-us-accordion {
        padding: 24px 28px 24px 32px;
        border: 1px solid var(--cl-grey-ii);
        border-radius: 16px;
        background: var(--cl-green-50);
        transition: .3s;

        &-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          user-select: none;
          cursor: pointer;
          transition: 0.5s;

          &__title {
            color: var(--cl-black);
            transition: 0.3s;
          }

          &__btn {
            width: 36px;
            height: 36px;
            display: grid;
            place-content: center;
            background: var(--cl-green-300);
            border-radius: 4px;
            transition: .3s;

            div {
              position: relative;
              width: 13px;
              height: 1.5px;
              background: var(--cl-dark);
              transition: 0.3s;
              opacity: 1;

              &:last-child {
                transform: rotate(-90deg) translateX(1.5px);
              }
            }
          }

          &:hover {
            .contact-us-accordion-top__title {
              @media (min-width: 1024px) {
                transform: translateX(10px);
              }
            }
          }
        }

        &-bottom {
          opacity: 0;
          max-height: 0;
          transition: 0.5s;
          overflow: hidden;

          &__links {
            display: flex;
            flex-direction: column;
            gap: ac(24px, 18px);
            padding-top: ac(28px, 18px);
            border-top: 1px solid var(--cl-green-300);

            a {
              display: inline-flex;
              align-items: center;
              gap: 8px;
              transition: 0.3s;
              color: var(--cl-dark);

              i {
                font-size: 18px;
                color: var(--cl-green-300);
              }

              &:hover {
                color: var(--cl-green-300);
              }
            }
          }
        }

        &.active {
          background: var(--cl-white);
          border-color: var(--cl-green-300);

          .contact-us-accordion {
            &-top__btn {
              background: var(--cl-grey-ii);

              div {
                &:last-child {
                  opacity: 0;
                }
              }
            }
          }
          .contact-us-accordion-top {
            margin-bottom: 16px;
          }

          .contact-us-accordion-bottom {
            opacity: 1;
            max-height: 1000px;
            transition: 0.5s;
          }
        }
      }
    }
  }
}
