.section-search-results {
  position: relative;
  overflow: hidden;
  padding: ac(80px, 30px) 0;
  background: var(--cl-green-50);

  &__wr {
    display: flex;
    flex-direction: column;
    gap: ac(60px, 30px);

    .pagination {
      margin-left: 0.8%;

      @media (max-width: 768px) {
        margin-left: auto;
      }
    }
  }

  &-filters {
    display: flex;
    flex-direction: column;
    gap: ac(24px, 20px);

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;

      &__title {
        color: var(--cl-black);
      }

      &__sortby {
        min-width: ac(260px, 230px);

        .choices {
          &::after {
            content: '';
            background: var(--cl-black);
          }
        }

        .choices__placeholder {
          opacity: 1;
        }

        .choices__item {
          &:first-child {
            /* pointer-events: none; */
          }
        }

        .choices__inner {
          color: var(--cl-black);
          border: 1px solid var(--cl-grey-ii);
          border-radius: 6px;
          background: rgba(255, 255, 255, 0.1);
          box-shadow: none;
          min-width: ac(260px, 230px);
        }
      }

      @media (max-width: 551px) {
        flex-direction: column;
        align-items: stretch;
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--cl-green-300);

      &__item {
        position: relative;
        color: var(--cl-grey-i);
        font-feature-settings: 'liga' off, 'clig' off;
        text-overflow: ellipsis;
        transition: 0.3s;
        padding: 17px 28px;
        padding-right: 14px;
        cursor: pointer;
        user-select: none;

        label {
          cursor: pointer;
        }

        input {
          visibility: hidden;
          display: none;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          right: 0;
          width: 100%;
          height: 4px;
          background: transparent;
          transition: 0.3s;
        }

        &.active {
          color: var(--cl-green-500);

          &::after {
            background: var(--cl-green-300);
          }
        }

        &:hover {
          color: var(--cl-green-500);
        }

        @media (max-width: 551px) {
          padding: 8px 14px;
        }
      }
    }
  }

  &__news {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: ac(24px, 20px);

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: center;
    }

    @media (max-width: 551px) {
      display: flex;
      flex-direction: column;
    }

    .search-news-card {
      position: relative;
      min-height: ac(365px, 275px);
      overflow: hidden;
      background: var(--cl-dark);
      border-radius: 24px;
      color: var(--cl-white);
      padding: ac(32px, 18px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 768px) {
        &:last-child {
          grid-column: span 2;
        }
      }

      &-wide {
        width: 100%;
        grid-column: span 2;

        @media (max-width: 1024px) {
          grid-column: auto;
        }
      }

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(43, 51, 69, 0.24) 0%,
          rgba(43, 51, 69, 0.8) 100%
        );
        z-index: 1;
      }

      &::after {
        opacity: 0.3;
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(97, 209, 148, 0) 50%,
          rgba(97, 209, 148, 0.5) 100%
        );
        transition: 0.3s;
        z-index: 1;
      }

      &__bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__date {
        position: relative;
        z-index: 2;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: var(--font-main);
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        padding: 11px 16px;
        border-radius: 8px;
        border: 1px solid var(--cl-green-500);
        background: rgba(43, 51, 69, 0.7);
        max-width: 122px;
        display: grid;
        place-content: center;
      }

      &__content {
        position: relative;
        z-index: 2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--cl-white);
        font-family: var(--font-main);
        font-size: ac(24px, 18px);
        font-weight: 500;
        line-height: 1.36;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
