.section-more-blogs {
  position: relative;
  overflow: hidden;
  padding: ac(80px, 30px) 0 ac(60px, 30px);
  border-radius: ac(60px, 30px) ac(60px, 30px) 0px 0px;
  background: var(--cl-green-50);
  max-width: 100vw;
  width: 100%;

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 551px) {
      right: -200px;
      top: -50px;
    }
  }

  &-top {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    &__title {
      max-width: 47%;
      color: var(--cl-dark);
      font-family: var(--font-main);
      font-size: ac(48px, 28px);
      font-weight: 400;
      line-height: 1.36;
      letter-spacing: -0.24px;

      @media (max-width: 800px) {
        max-width: 40%;
      }

      @media (max-width: 768px) {
        max-width: 60%;
      }

      @media (max-width: 551px) {
        max-width: 100%;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: ac(16px, 12px);

      @media (max-width: 551px) {
        flex-direction: column;

        & > * {
          width: 100%;
        }
      }
    }
  }

  .blogs-swiper-wr {
    margin-top: ac(60px, 30px);

    .swiper {
      overflow: visible !important;
    }

    .swiper-buttons {
      margin-left: auto;
      margin-right: ac(48px, 0px);
      margin-top: ac(48px, 32px);
    }

    .swiper-pagination-progressbar {
      background: var(--cl-light-blue-i);
      position: relative;
      height: 3px;
      border-radius: 10px;
      width: 84.7%;
      transform: translateY(-25px);

      @media (max-width: 800px) {
        width: 75%;
      }

      @media (max-width: 551px) {
        width: 60%;
      }

      @media (max-width: 374px) {
        width: 55%;
      }
    }

    .swiper-pagination-progressbar-fill {
      background: var(--cl-green-300);
      position: absolute;
      left: 0;
      top: -2px;
      width: 100%;
      height: calc(100% + 3px);
    }
  }
}

.blogs-card {
  position: relative;
  z-index: 1;
  min-height: ac(600px, 400px);
  overflow: hidden;
  border-radius: 32px;

  display: flex;
  flex-direction: column;

  &:hover {
    .blogs-card-img {
      transform: scale(1.05);
    }
  }

  &-img {
    position: relative;
    overflow: hidden;
    transition: .3s;

    &::before {
      content: '';
      display: block;
      padding-top: 63.7%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-content {
    flex: 1;
    min-height: 327px;
    position: relative;
    border-radius: 32px;
    background: var(--cl-white);
    margin-top: -32px;
    z-index: 2;
    padding: ac(32px, 26px);
    display: flex;
    flex-direction: column;
    gap: ac(16px, 14px);

    &__date {
      color: var(--cl-green-500);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: var(--font-main);
      font-size: ac(16px, 14px);
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    &__title {
      color: var(--cl-black);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__descr {
      color: var(--cl-black);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: auto;
    }

    &__btn {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: auto;
      width: fit-content;

      .btn-bordered-green {
        transition: 0.3s;
      }

      span {
        color: var(--cl-black);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: var(--font-main);
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        transition: 0.3s;
      }

      &:hover {
        .btn-bordered-green {
          color: var(--cl-dark);
        }

        span {
          color: var(--cl-green-300);
        }
      }
    }
  }
}
