.section-solutions-accordion {
  position: relative;
  overflow: hidden;
  padding: ac(80px, 30px) 0;
  background: var(--cl-green-50);

  &__wr {
    display: flex;
    align-items: flex-start;
    gap: ac(80px, 30px);

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &-left {
    flex: 1;
    max-width: 37.58%;
    width: 100%;

    @media (max-width: 1024px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }

    &__title {
      color: var(--cl-black);
      margin-bottom: ac(32px, 24px);
    }

    &__tabs {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .solutions-accordion-tab {
        padding: 12px 12px 12px 20px;
        border: 1px solid var(--cl-grey-ii);
        border-radius: 8px;
        background: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;

        &__title {
          color: var(--cl-black);
          transition: 0.3s;
        }

        &__btn {
          width: 36px;
          height: 36px;
          display: grid;
          place-content: center;
          background: var(--cl-green-300);
          border-radius: 4px;

          div {
            position: relative;
            width: 13px;
            height: 1.5px;
            background: var(--cl-dark);
            transition: 0.3s;
            opacity: 1;

            &:last-child {
              transform: rotate(-90deg) translateX(1.5px);
            }
          }
        }

        &.active {
          border: 1px solid var(--cl-green-300);
          background: var(--cl-white);

          .solutions-accordion-tab {
            &__btn {
              background: var(--cl-grey-ii);

              div {
                &:last-child {
                  opacity: 0;
                }
              }
            }
          }
        }

        &:hover {
          .solutions-accordion-tab__title {
            transform: translateX(10px);
          }
        }
      }
    }
  }

  &-right {
    flex: 1;
    max-width: 57.58%;
    width: 100%;
    margin-top: 10px;

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }

    @media (max-width: 551px) {
      max-width: 100%;
    }

    .solutions-accordion-card {
      position: relative;
      overflow: visible;
      display: flex;
      flex-direction: column-reverse;

      &:nth-child(odd) {
        .solutions-accordion-card-img {
          margin-left: auto;
          margin-right: 0;
          max-width: 80%;
        }

        .solutions-accordion-card-content {
          margin-left: 0;
          margin-right: auto;
          max-width: 60%;
        }
      }

      &:nth-child(even) {
        .solutions-accordion-card-img {
          margin-left: 0;
          margin-right: auto;
          max-width: 80%;
        }

        .solutions-accordion-card-content {
          margin-left: auto;
          margin-right: 0;
          max-width: 60%;
        }
      }

      @media (max-width: 551px) {
        &:nth-child(odd),
        &:nth-child(even) {
          .solutions-accordion-card-img,
          .solutions-accordion-card-content {
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
          }
        }
        min-height: unset;
      }

      &-img {
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        border-radius: 16px;
        z-index: 1;

        &::before {
          content: '';
          display: block;
          padding-top: 87.5%;
        }

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s;
        }
      }

      &-content {
        position: relative;
        z-index: 2;
        max-width: 100%;
        width: 100%;
        min-height: 510px;
        height: auto;
        margin-top: -250px;
        transform: none;
        padding: ac(40px, 20px) ac(48px, 24px);
        background: var(--cl-dark);
        border: 1px solid var(--cl-green-300);
        border-radius: 16px;
        overflow: hidden;

        @media (max-width: 1024px) {
          min-height: 430px;
        }

        @media (max-width: 551px) {
          min-height: 370px;
          margin-top: -75px;
        }

        @media (max-width: 374px) {
          margin-top: -50px;
        }

        &-inner {
          height: 100%;

          &__decor {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;

            @media (max-width: 768px) {
              max-width: 50%;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        &__title {
          color: var(--cl-white);
          margin-bottom: 16px;
          position: relative;
          z-index: 1;
        }

        &__descr {
          width: calc(100% + 5px);
          padding-right: 5px;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
          z-index: 1;
          color: var(--cl-light-blue-i);
          --fz: ac(20px, 16px);
          --lh: 1.5;
          --lc: 8;
          font-size: var(--fz);
          line-height: var(--lh);
          max-height: calc(var(--fz) * var(--lh) * var(--lc));

          @media (max-width: 551px) {
            --lc: 6;
          }

          @media (min-width: 769px) {
            --lc: 8;
          }

          @media (max-width: 374px) {
            --lc: 6;
          }

          .simplebar-vertical {
            right: -5px;
          }

          .simplebar-scrollbar {
            &::before {
              background: var(--cl-green-300);
            }
          }

          .simplebar-track.simplebar-horizontal {
            display: none !important;
          }

          .simplebar-content-wrapper {
            overflow-x: hidden !important;
          }

          .simplebar-content {
            overflow-x: hidden;
            width: 100%;
          }
        }

        &__read-more {
          display: inline-flex;
          width: fit-content;
          margin-top: 13px;
          cursor: pointer;
          font-size: 18px;
          text-decoration: underline;
          color: var(--cl-light-blue-i);
          transition: 0.3s;

          &:hover {
            color: var(--cl-green-300);
          }
        }
      }
    }
  }

  .simplebar-vertical {
    right: -15px;
  }
}