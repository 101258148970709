.section-job-description {
  position: relative;
  padding: ac(60px, 30px) 0 ac(120px, 30px);
  background: var(--cl-white);

  &__wr {
    display: flex;
    align-items: flex-start;
    gap: ac(92px, 35px);

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: stretch;
    }
  }

  &-left {
    flex: 1;
    max-width: 67.56%;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    &__title {
      color: var(--cl-black);
      margin-bottom: ac(24px, 18px);
    }

    .content-element { 
      /* p:last-child,
      span:last-child {
        font-family: var(--font-main);
        font-size: ac(28px, 24px);
        font-weight: 300;
        line-height: 1.36;
      } */

      p + h3,
      span + h3 {
        margin-top: ac(40px, 20px);
      }

      h3 + p,
      h3 + span {
        margin-top: ac(24px, 18px);
      }

      h3 {
        color: var(--cl-dark);
        font-family: var(--font-main);
        font-size: ac(28px, 22px);
        font-weight: 500;
        line-height: 1.36;
      }

      ul,
      ol,
      p,
      a,
      li {
        font-size: ac(20px, 16px);
        line-height: 1.5;
        color: var(--cl-black);

        &:not(:last-child) {
          margin-bottom: ac(24px, 18px);
        }
      }
      
      a {
        transition: .3s;
        display: inline-block;
        text-decoration: underline;
        
        &:hover {
          color: var(--cl-green-500);
        }
      }

      ul,
      ol {
        list-style: none;

        li {
          position: relative;
          padding-left: 38px;

          &::before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16' fill='none'%3E%3Cpath d='M2 14L8 8L2 2' stroke='%2361D194' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            left: 12px;
            top: 5px;
          }

          h4 {
            margin-bottom: 12px;
          }
        }
      }

      h3 + ul,
      h3 + ol {
        margin-top: ac(24px, 18px);
      }

      ul + h3,
      ol + h3 {
        margin-top: ac(40px, 20px);
      }

      .header {
        all: unset;
        position: relative;
      }

      .footer {
        all: unset !important;
        position: relative !important;
      }

    }

    .section-post-details-right-socials {
      margin: ac(48px, 24px) 0;
    }

    .section-hero__buttons {
      display: flex;
      align-items: center;
      gap: ac(32px, 24px);

      @media (max-width: 551px) {
        flex-direction: column;

        & > * {
          width: 100%;
        }
      }

      a.first {
        display: flex;
        align-items: center;
        gap: ac(12px, 10px);
        transition: all .3s;

        .btn-bordered-green {
          transition: 0.3s;
          background: var(--cl-white);
        }

        span {
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: var(--font-main);
          font-size: ac(16px, 14px);
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.5px;
          transition: 0.3s;
          color: var(--cl-dark);
        }

        &:hover {
          .btn-bordered-green {
            background: var(--cl-green-300);
            color: var(--cl-green-50);
            border-color: var(--cl-green-50);
          }

          span {
            color: var(--cl-green-500);
          }
        }
      }
    }
    
  } 
 
  &-right {
    position: sticky;
    top: 90px;
    right: 0;
    flex: 1;
    max-width: 25.45%;
    display: flex;
    flex-direction: column;
    gap: ac(24px, 20px);
    padding-right: ac(24px, 0px);

    @media (max-width: 767px) {
      max-width: 80%;
    }

    @media (max-width: 551px) {
      max-width: 100%;
    }

    &__title {
      color: var(--cl-green-500);
    }

    &__card {
      min-height: auto;
    }
  }
}
