.headline-1 {
  font-family: var(--font-main);
  font-weight: 300;
  font-size: ac(120px, 54px);
  line-height: 1.2;
}

.headline-2 {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: ac(80px, 44px);
  line-height: 1.2;
}

.headline-3 {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: ac(56px, 38px);
  line-height: 1.2;
}
 
.headline-4 {
  font-family: var(--font-main);
  font-weight: 400;
  font-size: ac(48px, 35px);
  line-height: 1.4;
}

.headline-5 {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: ac(34px, 26px);
  line-height: 1.36;
}

.headline-6 {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: ac(28px, 22px);
  line-height: 1.36;
}

.subtitle-1 {
  font-family: var(--font-main);
  font-weight: 300;
  font-size: ac(26px, 22px);
  line-height: 1.36;
}

.subtitle-2 {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: ac(22px, 18px);
  line-height: 1.36;
}

.subtitle-3 {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: ac(20px, 18px);
  line-height: 1.36;
}

.subtitle-4 {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: ac(18px, 15px);
  line-height: 1.36;
}

.body-text-1 {
  font-family: var(--font-main);
  font-weight: 400;
  font-size: ac(20px, 16px);
  line-height: 1.5;
}

.body-text-1-light {
  font-family: var(--font-main);
  font-weight: 300;
  font-size: ac(20px, 16px);
  line-height: 1.5;
}

.body-text-2 {
  font-family: var(--font-main);
  font-weight: 400;
  font-size: ac(18px, 15px);
  line-height: 1.5;
  letter-spacing: 0.045px;

  @media (max-width: 551px) {
    line-height: 1.4;
  }
}

.body-text-2-light {
  font-family: var(--font-main);
  font-weight: 300;
  font-size: ac(18px, 15px);
  line-height: 1.5;
}

a {
  width: fit-content;
  display: inline-flex;
}

.thank-you-text {
  font-family: var(--font-main);
  font-weight: 500;
  font-size: ac(38px, 22px);
  line-height: 1.36;
  color: var(--cl-white);
  position: relative;
  z-index: 999;
}

.content-element {
  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }


  h1 {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(120px, 54px);
    line-height: 1.2;
  }

  h2 {
    font-family: var(--font-main);
    font-weight: 500;
    font-size: ac(80px, 44px);
    line-height: 1.2;
  }

  h3 {
    font-family: var(--font-main);
    font-weight: 500;
    font-size: ac(56px, 24px);
    line-height: 1.2;
  }

  h4 {
    font-family: var(--font-main);
    font-weight: 400;
    font-size: ac(56px, 24px);
    line-height: 1.4;
  }

  h5 {
    font-family: var(--font-main);
    font-weight: 500;
    font-size: ac(34px, 26px);
    line-height: 1.36;
  }

  h6 {
    font-family: var(--font-main);
    font-weight: 500;
    font-size: ac(28px, 22px);
    line-height: 1.36;
  }

  ul,
  ol,
  p {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(28px, 22px);
    line-height: 1.36;

    &:not(:last-child) {
      margin-bottom: ac(24px, 18px);
    }
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.mask {
  overflow: hidden;
  display: inline-block;
}

[class^='headline-'] .word {
  display: inline-block;
  transform: translateY(100%);
  opacity: 0;
  animation: slideUp 0.5s ease forwards;
}

/* Animation for headlines */
[class^='headline-'] .mask:nth-child(1) .word {
  animation-delay: 0.25s;
}

[class^='headline-'] .mask:nth-child(2) .word {
  animation-delay: 0.5s;
}

[class^='headline-'] .mask:nth-child(3) .word {
  animation-delay: 0.75s;
}

[class^='headline-'] .mask:nth-child(4) .word {
  animation-delay: 1s;
}

[class^='headline-'] .mask:nth-child(5) .word {
  animation-delay: 1.25s;
}

[class^='headline-'] .mask:nth-child(6) .word {
  animation-delay: 1.5s;
}

[class^='headline-'] .mask:nth-child(7) .word {
  animation-delay: 1.75s;
}

[class^='headline-'] .mask:nth-child(8) .word {
  animation-delay: 2s;
}
