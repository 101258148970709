.section-studies {
  position: relative;
  overflow: hidden;
  padding: ac(120px, 40px) 0 ac(60px, 30px);
  background: var(--cl-green-50);

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 551px) {
      right: -200px;
      top: -50px;
    }
  }

  &__title {
    color: var(--cl-black);
    position: relative;
    z-index: 1;
  }
}

.studies-swiper-wr {
  margin-top: ac(60px, 30px);
  position: relative;
  z-index: 1;

  .swiper {
    overflow: visible !important;
  }

  .swiper-buttons {
    margin-top: ac(48px, 24px);
    margin-left: auto;
    margin-right: ac(48px, 0px);
  }

  .swiper-button {
    &:hover {
      background: var(--cl-white);
      color: var(--cl-dark);
      border-color: var(--cl-light-blue-i);
    }
  }

  .swiper-pagination-progressbar {
    position: relative;
    width: 84.7%;
    height: 3px;
    background: var(--cl-light-blue-i);
    border-radius: 10px;
    transform: translateY(-25px);

    @media (max-width: 800px) {
      width: 75%;
    }

    @media (max-width: 551px) {
      width: 60%;
    }

    @media (max-width: 374px) {
      width: 55%;
    }
  }

  .swiper-pagination-progressbar-fill {
    background: var(--cl-green-300);
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: calc(100% + 3px);
  }

  .studies-card {
    min-height: ac(418px, 375px);
    padding: ac(24px, 18px) ac(32px, 26px) ac(24px, 18px) ac(24px, 18px);
    overflow: hidden;
    background: var(--cl-white);
    border: 1px solid var(--cl-grey-ii);
    border-radius: 16px;
    cursor: pointer;
    transition: .3s;

    display: flex;
    flex-direction: column;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &-top {
      display: flex;
      align-items: center;
      gap: ac(24px, 18px);

      &-logo-cont {
        padding: ac(42px, 21px) ac(27px, 14px);
        border: 1px solid var(--cl-green-300);
        border-radius: 8px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: ac(12px, 8px);

        &__title {
          color: var(--cl-black);
        }

        &__subtitle {
          color: var(--cl-green-500);

          @media (max-width: 374px) {
            font-size: 15px;
          }
        }
      }
    }

    &-main {
      flex: 1;
      margin-top: ac(24px, 12px);
      margin-bottom: 8px;

      .content-element {
        --fz: ac(20px, 16px);
        --lh: 1.5;
        --lc: 8;
        max-height: calc(var(--fz) * var(--lh) * var(--lc));

        font-size: var(--fz);
        line-height: var(--lh);

        .simplebar-vertical {
          right: -15px;
          width: 3px;
        }

        .simplebar-scrollbar {
          width: 3px;
          background: var(--cl-green-500);

          &::before {
            background: var(--cl-green-500);
          }
        }

        ul,
        ol,
        p {
          color: var(--cl-black);
          font-family: var(--font-main);
          font-size: ac(20px, 16px);
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.05px;
        }
      }
    }

    &-bottom {
      display: flex;
      align-items: center;
      gap: ac(12px, 8px);
      width: fit-content;
      margin-left: auto;
      transition: 0.3s;

      &__link {
        display: flex;
        align-items: center;
        gap: ac(12px, 10px);
      }

      span {
        transition: 0.3s;
      }

      &__btn {
        transition: 0.3s;
      }

      &:hover {
        span {
          color: var(--cl-green-500);
        }

        .studies-card-bottom__btn {
          background: var(--cl-green-500);
          color: var(--cl-white);
          border-color: var(--cl-white);
        }
      }
    }
  }
}
