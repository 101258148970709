.section-similar-jobs {
  position: relative;
  overflow: hidden;
  background: var(--cl-green-50);
  padding: ac(80px, 30px) 0 ac(60px, 30px);

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 551px) {
      right: -200px;
      top: -50px;
    }
  }

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 24px;
    position: relative;
    z-index: 1;

    @media (max-width: 551px) {
      flex-direction: column;
      align-items: stretch;

      & > * {
        width: 100%;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: ac(24px, 18px);
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: ac(24px, 18px);

      @media (max-width: 551px) {
        flex-direction: column;

        & > * {
          width: 100%;
        }
      }
    }
  }

  .similar-jobs-swiper-wr {
    margin-top: ac(60px, 30px);

    .swiper {
      overflow: visible;
    }

    .swiper-buttons {
      margin-left: auto;
      margin-right: ac(48px, 0px);
      margin-top: ac(48px, 32px);
    }

    .swiper-pagination-progressbar {
      background: var(--cl-light-blue-i);
      position: relative;
      height: 3px;
      border-radius: 10px;
      width: 84.7%;
      transform: translateY(-25px);

      @media (max-width: 800px) {
        width: 75%;
      }

      @media (max-width: 551px) {
        width: 60%;
      }

      @media (max-width: 374px) {
        width: 55%;
      }
    }

    .swiper-pagination-progressbar-fill {
      background: var(--cl-green-300);
      position: absolute;
      left: 0;
      top: -2px;
      width: 100%;
      height: calc(100% + 3px);
    }

    @media (max-width: 374px) {
      .jobs-search-result-card {
        width: 100%;
      }
    }

    .jobs-search-result-card:hover {
      p {
        color: var(--cl-white);
      }
    }
  }
}
