.section-team {
  position: relative;
  overflow: hidden;
  padding: ac(120px, 40px) 0 ac(60px, 32px);
  background: var(--cl-green-50);

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (max-width: 551px) {
      right: -200px;
      top: -50px;
    }
  }

  &__title {
    color: var(--cl-black);
    position: relative;
    z-index: 1;
  }

  .team-swiper-wr {
    margin-top: ac(60px, 30px);
    position: relative;
    z-index: 1;

    .swiper {
      overflow: visible !important;
    }

    .swiper-buttons {
      margin-top: ac(48px, 24px);
      margin-left: auto;
      margin-right: ac(48px, 0px);
    }

    .swiper-button {
      width: ac(56px, 48px);
      height: ac(56px, 48px);

      &:hover {
        background: var(--cl-white);
        color: var(--cl-dark);
        border-color: var(--cl-light-blue-i);
      }
    }

    .swiper-pagination-progressbar {
      position: relative;
      width: 84.7%;
      height: 3px;
      background: var(--cl-light-blue-i);
      border-radius: 10px;
      transform: translateY(-30px);

      @media (max-width: 800px) {
        width: 75%;
        transform: translateY(-25px);
      }

      @media (max-width: 551px) {
        width: 60%;
      }

      @media (max-width: 374px) {
        width: 55%;
      }
    }

    .swiper-pagination-progressbar-fill {
      background: var(--cl-green-300);
      position: absolute;
      left: 0;
      top: -2px;
      width: 100%;
      height: calc(100% + 3px);
    }
  }
}

.team-card {
  position: relative;
  min-height: ac(437px, 375px);
  border-radius: 16px;
  border: 1px solid rgba(97, 209, 148, 0.3);
  background: var(--cl-white);
  overflow: hidden;
  transition: 0.3s;
  cursor: pointer;
  @media (max-width: 374px) {
    min-height: 340px;
  }

  &-link {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2; 
  }

  &-img {
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      padding-top: 80%;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(36, 165, 103, 0) 60%,
        rgba(36, 165, 103, 0.2) 100%
      );
      z-index: 2;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }

  &-content {
    padding: ac(28px, 15px) ac(24px, 15px) ac(24px, 15px) ac(24px, 15px);
    border-top: 5px solid var(--cl-green-300);

    &__name {
      color: var(--cl-black);
      font-weight: 500;
      margin-bottom: 8px;
    }

    &__position {
      color: var(--cl-green-500);
    }

    &__socials {
      display: flex;
      align-items: center;
      gap: ac(30px, 15px);
      margin-top: ac(28px, 20px);

      a {
        color: var(--cl-green-500);
        font-size: ac(22px, 20px);
        transition: 0.3s;
        z-index: 5;

        &:hover {
          transform: scale(1.2);

          @media (max-width: 551px) {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  &.section-job-description-right__card {
    .team-card-content__name , .team-card-content__position {
      text-align: left;
    }
  }
}
