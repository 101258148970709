.section-jobs-search-result {
  position: relative;
  /* overflow: hidden; */
  background: var(--cl-green-50);
  padding: ac(80px, 30px) 0;

  &__decor {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wr {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: ac(48px, 24px);

    .pagination {
      /* margin-left: 5%; */
      margin-top: ac(56px, 30px);

      @media (max-width: 768px) {
        margin-left: auto;
      }
    }
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 650px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__tltle {
      color: var(--cl-black);
      margin-left: 25%;

      @media (max-width: 900px) {
        margin-left: 0;
      }
    }

    &__sort {
      display: flex;
      align-items: center;
      gap: 16px;

      @media (max-width: 551px) {
        flex-direction: column;
        align-items: stretch;
        width: 100%;

        & > * {
          min-width: 100%;
        }
      }

      .input-form {
        .choices {
          height: 56px;
          min-width: ac(204px, 150px);

          @media (max-width: 551px) {
            min-width: 100%;
            width: 100%;
          }

          &::after {
            content: '';
            background: var(--cl-black);
          }
        }

        .choices__inner {
          color: var(--cl-black);
          font-family: var(--font-main);
          min-width: ac(204px, 150px);
          border-radius: 6px;
          border: 1px solid var(--cl-grey-ii);
          background: var(--cl-green-50);
          box-shadow: none;

          @media (max-width: 551px) {
            min-width: 100%;
            width: 100%;
          }
        }
      }

      .choices__placeholder {
        opacity: 1;
      }

      .choices__item {
        &:first-child {
          /* pointer-events: none; */
        }
      }

      .choices__list--dropdown,
      .choices__list[aria-expanded] {
        margin-top: -60px;
      }
    }
  }

  &-main {
    display: flex;
    align-items: flex-start;
    gap: ac(60px, 30px);

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-content {
    flex: 1;
  }

  &-filters {
    position: sticky;
    top: 100px;
    flex: 1;
    max-width: 20.9%;
    max-height: 88vh;
    padding-right: 16px;

    .simplebar-track.simplebar-vertical {
      width: 3px;
      background: #d3ebf2;
      border-radius: 4px;
      transform: none;
      top: 0;
      bottom: 0;
      overflow: visible;

      .simplebar-scrollbar {
        background: #61d194;
        border-radius: 4px;
        width: 6px;
        left: -1.5px;

        &:before {
          content: none;
        }
      }
    }

    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
      overflow: visible;
      max-height: auto;
    }

    &__title {
      margin-bottom: ac(37px, 18px);
      color: var(--cl-black);
    }

    &__accordions {
      display: flex;
      flex-direction: column;
      gap: ac(32px, 24px);

      .search-result-filter-accordion {
        &-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: var(--cl-black);
          cursor: pointer;

          svg {
            transform: rotate(180deg);
            transition: 0.3s;

            path {
              transition: 0.3s;
            }
          }

          &__title {
            transition: 0.3s;
          }

          &:hover {
            .search-result-filter-accordion-top__title {
              color: var(--cl-green-300);
            }

            svg {
              path {
                stroke: var(--cl-green-300);
              }
            }
          }
        }

        &-content {
          border-top: 1px solid var(--cl-grey-ii);
          display: flex;
          flex-direction: column;
          gap: 12px;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          transition: 0.3s;
        }

        &.active {
          .search-result-filter-accordion {
            &-top {
              padding-bottom: 13px;

              svg {
                transform: rotate(0deg);
              }
            }

            &-content {
              padding-top: 17px;
              opacity: 1;
              max-height: 1000px;
            }
          }
        }
      }

      .search-result-filter {
        &__title {
          margin-bottom: 17px;
        }

        &__content {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .input-form {
          .choices {
            height: 56px;
            min-width: ac(204px, 150px);

            &::after {
              content: '';
              background: var(--cl-black);
            }
          }

          .choices__inner {
            color: var(--cl-black);
            font-family: var(--font-main);
            min-width: ac(204px, 150px);
            border-radius: 6px;
            border: 1px solid var(--cl-grey-ii);
            background: var(--cl-green-50);
            box-shadow: none;
          }
        }

        .choices__placeholder {
          opacity: 1;
        }

        .choices__item {
          &:first-child {
            /* pointer-events: none; */
          }
        }

        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-top: -60px;
        }
      }
    }
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }
}

.jobs-search-result-card {
  position: relative;
  flex: 1;
  min-height: ac(430px, 380px);
  padding: ac(32px, 18px);
  background: var(--cl-white);
  border: 1px solid var(--cl-grey-ii);
  border-radius: 20px;
  transition: 0.3s;

  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    max-width: 425px;
  }

  @media (max-width: 1024px) {
    max-width: 353px;
  }

  @media (max-width: 768px) {
    min-height: 320px;
    max-width: 348px;
  }

  @media (max-width: 551px) {
    min-height: 360px;
    max-width: 100%;
  }

  @media (max-width: 425px) {
    max-width: 375px;
  }

  @media (max-width: 390px) {
    max-width: 330px;
  }

  @media (max-width: 374px) {
    max-width: 275px;
  }


  &__title {
    color: var(--cl-black);
    margin-bottom: ac(19px, 15px);
    transition: 0.3s;
    @mixin max-line-length 2;
    text-overflow: ellipsis;
  }

  &__location {
    display: flex;
    align-items: center;
    gap: ac(17px, 14px);
    color: var(--cl-green-500);
    margin-bottom: ac(43px, 30px);
    transition: 0.3s;

    svg {
      min-width: 15px;
      width: 15px;
      path {
        transition: 0.3s;
      }
    }
  }

  &__descr {
    transition: 0.3s;
  }

  &__details {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 4px;
    row-gap: 8px;

    @media (max-width: 374px) {
      flex-wrap: wrap;
    }

    li {
      padding: 8px 16px;
      color: var(--cl-grey-i);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: var(--font-main);
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      border: 1px solid var(--cl-green-300);
      border-radius: 100px;
      display: grid;
      place-content: center;
      transition: 0.3s;
    }

    &:not(:last-child) {
      margin-bottom: ac(16px, 14px);
    }
  }

  &-interact {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ac(16px, 14px);
    margin-left: auto;
    margin-top: auto;
    padding-top: ac(16px, 14px);
    border-top: 1px solid var(--cl-grey-ii);

    @media (max-width: 374px) {
      flex-direction: column;
      align-items: stretch;
      margin-top: 50px;

      .btn-green-transparent {
        width: 100%;
      }
    }

    &__view {
      display: inline-flex;
      align-items: center;
      gap: 10px;

      span {
        color: var(--cl-black);
        font-feature-settings: 'liga' off, 'clig' off;

        font-family: var(--font-main);
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        transition: 0.3s;
      }

      i {
        font-size: ac(13px, 12px);
        transition: 0.3s;
      }

      &:hover {
        span {
          color: var(--cl-green-300);
        }

        i {
          color: var(--cl-green-300);
        }
      }
    }
  }

  &:hover {
    box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.18);
    background: var(--cl-dark);

    .jobs-search-result-card {
      &__title {
        color: var(--cl-white);
      }

      &__location {
        color: var(--cl-grey-ii);

        svg {
          path {
            fill: var(--cl-grey-ii);
          }
        }
      }

      &__descr {
        color: var(--cl-white);
      }

      &__details {
        li {
          border-color: var(--cl-grey-i);
          color: var(--cl-grey-ii);
        }
      }

      &-interact {
        &__view {
          color: var(--cl-white);

          span {
            color: var(--cl-white);
          }
        }

        i {
          font-size: ac(13px, 12px);
          transition: 0.3s;
        }

        &:hover {
          span {
            color: var(--cl-green-300);
          }

          i {
            color: var(--cl-green-300);
          }
        }

        .btn-green-transparent {
          background: var(--cl-green-300);

          &:hover {
            background: var(--cl-white) !important;
            border-color: var(--cl-white);
          }
        }
      }
    }
  }
}
