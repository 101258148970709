.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-green-500) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto !important;
  background: var(--cl-white);

  color: var(--cl-black);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-light-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-red);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.visible {
    @media (min-width: 769px) {
      overflow: visible;
    }
  }
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1320px;
  width: perc(1320);

  @media (max-width: 1024px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
}

section {
  position: relative;
}

section,
footer,
header {
  position: relative;
  width: 100%;
  max-width: 100vw;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 150px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);

  /* p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  } */

  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  h1 {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(56px, 32px);
    line-height: 1.2;
  }

  h2 {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(48px, 26px);
    line-height: 1.2;
  }

  h3 {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(36px, 22px);
    line-height: 1.2;
  }

  h4 {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(32px, 18px);
    line-height: 1.4;
  }

  h5 {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(26px, 16px);
    line-height: 1.36;
  }

  h6 {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(24px, 15px);
    line-height: 1.36;
  }

  ul,
  ol,
  p,
  li {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(22px, 18px);
    line-height: 1.36;

    &:not(:last-child) {
      margin-bottom: ac(24px, 18px);
    }
  }

  ul,
  ol {
    list-style: none;

    li {
      position: relative;
      padding-left: 38px;

      &::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16' fill='none'%3E%3Cpath d='M2 14L8 8L2 2' stroke='%2361D194' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 12px;
        top: ac(7px, 5px);
      }

      h4 {
        margin-bottom: 12px;
      }
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
    text-align: center;
  }

  a {
    display: inline-block;
    margin-top: 20px;

    span {
      position: relative;
      top: -5px;
    }
  }
}

#site.cookie-terms-page,
.cookie-terms-section,
.single-page-404 {
  a {
    word-break: break-all;

    > * {
      color: var(--cl-green-500) !important;
      transition: 0.3s;

      &:hover {
        color: var(--cl-green-300) !important;
      }
    }
    color: var(--cl-green-500) !important;
    transition: 0.3s;

    &:hover {
      color: var(--cl-green-300) !important;
    }
  }
}

.grecaptcha-badge {
  z-index: 99999999999999999;
}
