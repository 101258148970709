.section-industries {
  position: relative;
  overflow: hidden;
  padding: ac(80px, 32px) 0 ac(60px, 48px);

  &-top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    @media (max-width: 551px) {
      gap: 18px;
    }

    &__title {
      max-width: 47%;
      color: var(--cl-dark);
      font-family: var(--font-main);
      font-size: ac(48px, 28px);
      font-weight: 400;
      line-height: 1.36;
      letter-spacing: -0.24px;

      @media (max-width: 800px) {
        max-width: 40%;
      }

      @media (max-width: 768px) {
        max-width: 60%;
      }

      @media (max-width: 551px) {
        max-width: 100%;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      gap: ac(16px, 12px);

      @media (max-width: 551px) {
        flex-direction: column;

        & > * {
          width: 100%;
        }
      }
    }
  }
}

.industries-swiper-wr {
  margin-top: ac(60px, 32px);

  .swiper {
    overflow: visible !important;
  }

  @media (max-width: 768px) {
    .swiper-slide-active {
      .industries-card {
        &-content {
          background: var(--cl-dark);

          &__title {
            color: var(--cl-white);
          }

          &__btn {
            right: ac(24px, 16px);
            bottom: ac(24px, 16px);
            border-color: var(--cl-white);
            color: var(--cl-dark);
            background: var(--cl-green-300);
          }
        }
      }
    }
  }

  .swiper-buttons {
    margin-left: auto;
    margin-right: ac(48px, 0px);
    margin-top: 60px;

    @media (max-width: 551px) {
      margin-top: 80px;
    }

    @media (max-width: 400px) {
      margin-top: 40px;
    }

    @media (max-width: 374px) {
      margin-top: 25px;
    }

    .swiper-button {
      width: ac(56px, 48px);
      height: ac(56px, 48px);
    }
  }

  .swiper-pagination-progressbar {
    background: var(--cl-light-blue-i);
    position: relative;
    height: 3px;
    border-radius: 10px;
    width: 84.7%;
    transform: translateY(-30px);

    @media (max-width: 800px) {
      width: 75%;
      transform: translateY(-25px);
    }

    @media (max-width: 551px) {
      width: 60%;
    }

    @media (max-width: 374px) {
      width: 55%;
    }
  }

  .swiper-pagination-progressbar-fill {
    background: var(--cl-green-300);
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: calc(100% + 3px);
  }

  .industries-card {
    position: relative;
    width: 100%;
    min-height: ac(455px, 333px);
    display: inline-block;

    &-img {
      position: relative;
      overflow: hidden;
      border-radius: 16px;
      z-index: 0;

      &::before {
        content: '';
        display: block;
        padding-top: 56.15%;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }

    &-content {
      position: relative;
      width: 100%;
      max-width: 91%;
      min-height: ac(210px, 198px);
      margin: 0 auto;
      background: var(--cl-white);
      padding: ac(24px, 20px) 24px ac(24px, 20px) ac(32px, 24px);
      display: flex;
      flex-direction: column;
      gap: ac(12px, 8px);
      border-radius: 16px;
      border: 1px solid var(--cl-light-blue-i);
      box-shadow: 2px 2px 28px 0px rgba(0, 0, 0, 0.12);
      margin-top: ac(-56px, -48px);
      z-index: 1;
      transition: 0.3s;

      @media (max-width: 1370px) {
        min-height: 250px;
      }

      @media (max-width: 1024px) {
        min-height: 250px;
      }

      @media (max-width: 551px) {
        min-height: 240px;
        height: auto;
      }

      @media (max-width: 374px) {
        min-height: 290px;
      }

      &__title {
        color: var(--cl-black);
        max-width: 360px;
        transition: 0.3s;
      }

      &__descr {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--cl-green-300);
        max-width: 360px;
        --fz: ac(18px, 15px);
        --lh: 1.36;
        --lc: 2;
        font-size: var(--fz);
        line-height: var(--lh);
        /* max-height: calc(var(--fz) * var(--lh) * var(--lc)); */

        @media (max-width: 374px) {
          --lc: 2;
        }

        .simplebar-vertical {
          right: -10px;
          width: 3px;
        }

        .simplebar-scrollbar {
          width: 3px;
          background: var(--cl-green-500);

          &::before {
            background: var(--cl-green-500);
          }
        }
      }

      &__btn {
        position: absolute;
        right: ac(32px, 16px);
        bottom: ac(32px, 16px);
        transition: 0.3s;
      }
    }

    &:hover {
      .industries-card {
        &-content {
          background: var(--cl-dark);

          &__title {
            color: var(--cl-white);
          }

          &__btn {
            right: ac(24px, 16px);
            bottom: ac(24px, 16px);
            border-color: var(--cl-white);
            color: var(--cl-dark);
            background: var(--cl-green-300);
          }
        }
      }
    }
  }
}
