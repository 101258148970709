.footer {
  background: var(--cl-dark);
  padding: ac(60px, 30px) 0 28px;

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: ac(60px, 24px);

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 40px;
    }

    &-left {
      &__img {
        width: ac(412px, 275px);
        transition: .3s;

        &:hover {
          transform: scale(1.05);
        }
      }

      &__text {
        color: var(--White, #fff);
        max-width: 375px;
        margin: ac(32px, 20px) 0 24px;

        @media (max-width: 1200px) {
          max-width: 300px;
        }

        @media (max-width: 1024px) {
          max-width: 240px;
        }

        @media (max-width: 768px) {
          max-width: 80%;
        }

        @media (max-width: 374px) {
          max-width: 100%;
        }
      }

      &__btn {
        @media (max-width: 551px) {
          width: 100%;
        }
      }
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 24px;

      @media (min-width: 1024px) {
        margin-top: 30px;
      }

      &__text {
        color: var(--cl-green-300);
      }

      &__socials {
        display: flex;
        align-items: center;
        gap: ac(25px, 30px);

        @media (max-width: 374px) {
          gap: 25px;
        }

        a {
          color: var(--cl-white);
          font-size: ac(20px, 26px);
          transition: all 0.3s ease;

          &:hover {
            color: var(--cl-green-300);
            transform: scale(1.2);
          }
        }
      }

      @media (max-width: 551px) {
        flex-direction: column;
        width: 100%;
        align-items: stretch;
      }
    }
  }

  &-main {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    max-width: 35%;
    margin-top: ac(-85px, -35px);
    margin-left: auto;
    padding-bottom: ac(38px, 19px);

    @media (max-width: 1024px) {
      max-width: 60%;
    }

    @media (max-width: 768px) {
      max-width: 100%;
      margin-top: 0;
      margin-left: 0;
    }

    @media (max-width: 551px) {
      flex-wrap: wrap;
    }

    @media (max-width: 374px) {
      gap: 16px;
    }

    &::after {
      position: absolute;
      content: '';
      top: ac(-33px, -20px);
      right: 0;
      width: calc(100% + 16vw);
      height: 1px;
      background: rgba(255, 255, 255, 0.1);

      @media (max-width: 768px) {
        display: none;
      }
    }

    &-section {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;

      @media (max-width: 551px) {
        flex: 1 1 45%;
      }

      &__title {
        color: var(--cl-green-300);
      }

      &__links {
        display: flex;
        flex-direction: column;
        gap: 16px;

        a {
          position: relative;
          color: var(--cl-light-blue-ii);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: var(--font-main);
          font-size: ac(16px, 15px);
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.08px;
          transition: all 0.3s ease;

          &::before {
            content: '';
            position: absolute;
            bottom: -2px;
            right: 0;
            width: 0;
            height: 2px;
            background: var(--cl-green-300);
            transition: all 0.3s ease;
          }

          &:hover {
            color: var(--cl-green-300);

            &::before {
              width: 100%;
              left: 0;
              background: var(--cl-green-300);
            }
          }
        }
      }
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ac(40px, 20px);
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    @media (max-width: 1250px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      border: 0;
    }

    @media (max-width: 551px) {
      align-items: flex-start;
      gap: 17px;
    }

    &__copy {
      color: var(--cl-light-blue-ii);
      font-family: var(--font-main);
      font-size: ac(14px, 13px);
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: 0.035px;
      opacity: 0.8;
    }

    &__policy {
      display: flex;
      align-items: center;
      column-gap: ac(32px, 12px);
      row-gap: 18px;

      @media (max-width: 551px) {
        flex-wrap: wrap;
      }

      a {
        transition: all 0.3s ease;
        opacity: 0.8;
        color: var(--cl-light-blue-ii);
        font-family: var(--font-main);
        font-size: ac(14px, 13px);
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.035px;
        text-decoration-line: underline;

        &:hover {
          color: var(--cl-green-300);
        }
      }
    }

    &__dev {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 150px;
        object-fit: contain;
      }

      @media (min-width: 1251px) {
        position: absolute;
        top: calc(ac(20px, 10px) + 50%);
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      @media (max-width: 1023px) {
        width: 100%;

        @media (min-width: 552px) {
          margin-top: 20px;
        }

        @media (max-width: 551px) {
          justify-content: flex-start;
        }
      }
    }
  }
}
