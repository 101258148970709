@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:wght@300..700&display=swap');

:root {
	--cl-white: #fff;
  --cl-black: #384154;
	--cl-dark: #2b3345;
	--cl-grey-i: #545f75;
	--cl-grey-ii: #d6d9e1;
	--cl-green-700: #126a44;
	--cl-green-500: #24a567;
	--cl-green-300: #61d194;
	--cl-green-50: #f0fbf5;
	--cl-light-blue-i: #d3ebf2;
	--cl-light-blue-ii: #edf7fa;
	--cl-light-blue-iii: #edf7fa;

  --font-main: "filson-pro", sans-serif;
  --font-second: "Red Hat Text", sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1024;

  --vh: calc(100vh / 100);

  --arw: 1;
  --arh: 1;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
