.input-search {
  color: var(--cl-white);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.08px;

  padding: 14px 32px;
  border-radius: 100px;
  border: 1px solid rgba(211, 235, 242, 0.3);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.12);
  height: 56px;
  @mixin transparent-bg-input var(--cl-white);

  &::placeholder {
    color: inherit;
  }
}

.input-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  label {
    color: var(--cl-white);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: var(--font-main);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.08px;
  }

  input {
    color: var(--cl-white);
    font-family: var(--font-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.035px;
    padding: 14px 20px;
    height: 56px;
    min-width: 275px;
    border: 1px solid rgba(211, 235, 242, 0.3);
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.12);
    @mixin transparent-bg-input var(--cl-white);
  }
}

.policy-checkbox {
  display: flex;
  align-items: center;
  gap: ac(13px, 10px);
  color: var(--cl-light-blue-i);
  font-family: var(--font-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.035px;
  opacity: 0.8;

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
    background: #f8f6f7;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
  }

  input[type='checkbox']:hover {
    border-color: #162c67;
    box-shadow: 0 0 5px rgba(22, 44, 103, 0.3);
  }

  input[type='checkbox']:checked {
    background: #f8f6f7;
    border-color: #162c67;
  }

  input[type='checkbox']::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 13px;
    height: 13px;
    background-color: #162c67;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12' fill='none'%3E%3Cpath d='M11.8263 1.07683C12.0537 0.851394 12.3607 0.724521 12.681 0.723637C13.0012 0.722754 13.3089 0.847931 13.5375 1.07211C13.7662 1.29628 13.8974 1.60144 13.9029 1.92161C13.9084 2.24178 13.7876 2.55124 13.5667 2.78308L7.0797 10.8918C6.96821 11.0119 6.83365 11.1083 6.68406 11.1752C6.53448 11.2421 6.37294 11.2781 6.20911 11.2811C6.04528 11.2842 5.88251 11.2541 5.73055 11.1928C5.57859 11.1315 5.44056 11.0402 5.3247 10.9243L1.02657 6.62458C0.906832 6.513 0.810791 6.37845 0.744179 6.22895C0.677567 6.07945 0.641749 5.91807 0.638862 5.75443C0.635974 5.59079 0.666077 5.42824 0.727374 5.27648C0.788671 5.12473 0.879906 4.98687 0.995636 4.87114C1.11137 4.75541 1.24922 4.66418 1.40098 4.60288C1.55273 4.54158 1.71528 4.51148 1.87892 4.51437C2.04256 4.51725 2.20395 4.55307 2.35345 4.61968C2.50295 4.6863 2.6375 4.78234 2.74907 4.90208L6.15182 8.3032L11.7955 1.11258C11.8055 1.09999 11.8164 1.08805 11.828 1.07683H11.8263Z' fill='%23162C67'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12' fill='none'%3E%3Cpath d='M11.8263 1.07683C12.0537 0.851394 12.3607 0.724521 12.681 0.723637C13.0012 0.722754 13.3089 0.847931 13.5375 1.07211C13.7662 1.29628 13.8974 1.60144 13.9029 1.92161C13.9084 2.24178 13.7876 2.55124 13.5667 2.78308L7.0797 10.8918C6.96821 11.0119 6.83365 11.1083 6.68406 11.1752C6.53448 11.2421 6.37294 11.2781 6.20911 11.2811C6.04528 11.2842 5.88251 11.2541 5.73055 11.1928C5.57859 11.1315 5.44056 11.0402 5.3247 10.9243L1.02657 6.62458C0.906832 6.513 0.810791 6.37845 0.744179 6.22895C0.677567 6.07945 0.641749 5.91807 0.638862 5.75443C0.635974 5.59079 0.666077 5.42824 0.727374 5.27648C0.788671 5.12473 0.879906 4.98687 0.995636 4.87114C1.11137 4.75541 1.24922 4.66418 1.40098 4.60288C1.55273 4.54158 1.71528 4.51148 1.87892 4.51437C2.04256 4.51725 2.20395 4.55307 2.35345 4.61968C2.50295 4.6863 2.6375 4.78234 2.74907 4.90208L6.15182 8.3032L11.7955 1.11258C11.8055 1.09999 11.8164 1.08805 11.828 1.07683H11.8263Z' fill='%23162C67'/%3E%3C/svg%3E");
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    opacity: 0;
    transition: all 0.3s ease;
  }

  input[type='checkbox']:checked::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  label {
    max-width: 380px;
    cursor: pointer;

    a {
      color: var(--cl-green-300);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: 0.035px;
      text-decoration-line: underline;
      transition: 0.3s;

      &:hover {
        color: var(--cl-white);
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--cl-black);
  font-family: var(--font-main);
  opacity: 1;

  label {
    font-family: var(--font-main);
    font-weight: 300;
    font-size: ac(18px, 15px);
    cursor: pointer;
  }

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    background: var(--cl-white);
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
  }

  input[type='checkbox']:hover {
    border-color: #162c67;
    box-shadow: 0 0 5px rgba(22, 44, 103, 0.3);
  }

  input[type='checkbox']:checked {
    background: var(--cl-green-300);
    border-color: var(--cl-green-300);
  }

  input[type='checkbox']::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 13px;
    height: 13px;
    background-color: var(--cl-black);
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12' fill='none'%3E%3Cpath d='M11.8263 1.07683C12.0537 0.851394 12.3607 0.724521 12.681 0.723637C13.0012 0.722754 13.3089 0.847931 13.5375 1.07211C13.7662 1.29628 13.8974 1.60144 13.9029 1.92161C13.9084 2.24178 13.7876 2.55124 13.5667 2.78308L7.0797 10.8918C6.96821 11.0119 6.83365 11.1083 6.68406 11.1752C6.53448 11.2421 6.37294 11.2781 6.20911 11.2811C6.04528 11.2842 5.88251 11.2541 5.73055 11.1928C5.57859 11.1315 5.44056 11.0402 5.3247 10.9243L1.02657 6.62458C0.906832 6.513 0.810791 6.37845 0.744179 6.22895C0.677567 6.07945 0.641749 5.91807 0.638862 5.75443C0.635974 5.59079 0.666077 5.42824 0.727374 5.27648C0.788671 5.12473 0.879906 4.98687 0.995636 4.87114C1.11137 4.75541 1.24922 4.66418 1.40098 4.60288C1.55273 4.54158 1.71528 4.51148 1.87892 4.51437C2.04256 4.51725 2.20395 4.55307 2.35345 4.61968C2.50295 4.6863 2.6375 4.78234 2.74907 4.90208L6.15182 8.3032L11.7955 1.11258C11.8055 1.09999 11.8164 1.08805 11.828 1.07683H11.8263Z' fill='%23162C67'/%3E%3C/svg%3E");
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='12' viewBox='0 0 14 12' fill='none'%3E%3Cpath d='M11.8263 1.07683C12.0537 0.851394 12.3607 0.724521 12.681 0.723637C13.0012 0.722754 13.3089 0.847931 13.5375 1.07211C13.7662 1.29628 13.8974 1.60144 13.9029 1.92161C13.9084 2.24178 13.7876 2.55124 13.5667 2.78308L7.0797 10.8918C6.96821 11.0119 6.83365 11.1083 6.68406 11.1752C6.53448 11.2421 6.37294 11.2781 6.20911 11.2811C6.04528 11.2842 5.88251 11.2541 5.73055 11.1928C5.57859 11.1315 5.44056 11.0402 5.3247 10.9243L1.02657 6.62458C0.906832 6.513 0.810791 6.37845 0.744179 6.22895C0.677567 6.07945 0.641749 5.91807 0.638862 5.75443C0.635974 5.59079 0.666077 5.42824 0.727374 5.27648C0.788671 5.12473 0.879906 4.98687 0.995636 4.87114C1.11137 4.75541 1.24922 4.66418 1.40098 4.60288C1.55273 4.54158 1.71528 4.51148 1.87892 4.51437C2.04256 4.51725 2.20395 4.55307 2.35345 4.61968C2.50295 4.6863 2.6375 4.78234 2.74907 4.90208L6.15182 8.3032L11.7955 1.11258C11.8055 1.09999 11.8164 1.08805 11.828 1.07683H11.8263Z' fill='%23162C67'/%3E%3C/svg%3E");
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center;
    opacity: 0;
    transition: all 0.3s ease;
  }

  input[type='checkbox']:checked::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

.choices {
  width: 100%;
  height: 56px;
  position: relative;
  /* z-index: 100; */

  &::after {
    content: '';
    width: 16px;
    height: 10px;
    background: var(--cl-white);
    position: absolute;
    top: 50%;
    transform: translateY(-30%) translateX(-10px);

    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='10' viewBox='0 0 16 10' fill='none'%3E%3Cpath d='M8 6.70426L13.9377 0.766592C14.0483 0.652025 14.1807 0.560643 14.327 0.497777C14.4734 0.43491 14.6308 0.40182 14.79 0.400436C14.9493 0.399052 15.1073 0.429402 15.2547 0.489715C15.4021 0.550029 15.536 0.639097 15.6487 0.751724C15.7613 0.864351 15.8504 0.998281 15.9107 1.1457C15.971 1.29312 16.0013 1.45107 16 1.61034C15.9986 1.76961 15.9655 1.92702 15.9026 2.07336C15.8397 2.21971 15.7484 2.35207 15.6338 2.46273L8.84807 9.24846C8.62312 9.47333 8.31807 9.59966 8 9.59966C7.68193 9.59966 7.37688 9.47333 7.15193 9.24846L0.366202 2.46273C0.251635 2.35207 0.160252 2.21971 0.0973859 2.07336C0.0345198 1.92702 0.00142933 1.76961 4.52903e-05 1.61034C-0.00133875 1.45107 0.0290114 1.29312 0.0893247 1.1457C0.149638 0.998281 0.238707 0.864351 0.351334 0.751724C0.463961 0.639097 0.59789 0.550029 0.745308 0.489715C0.892726 0.429402 1.05068 0.399052 1.20995 0.400436C1.36922 0.40182 1.52663 0.43491 1.67297 0.497777C1.81932 0.560643 1.95168 0.652025 2.06234 0.766592L8 6.70426Z' fill='white'/%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-position: center;

    transition: transform 250ms ease;
  }

  &.is-open {
    &::after {
      transform: translateY(3px) translateX(-10px) rotate(180deg);
    }
  }

  &.is-flipped {
    .choices__list--dropdown {
      bottom: calc(100% + 10px);
    }
  }
}

.choices[data-type*='select-one'].is-open::after {
  border-color: transparent !important;
}

.choices__list--dropdown .choices__item,
.choices__list[aria-expanded] .choices__item {
  padding: 14px 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.035px;
}

.choices__inner {
  color: var(--cl-white);
  font-family: var(--font-main);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.035px;
  padding: 14px 20px;
  height: 56px;
  min-width: 275px;
  border: 1px solid rgba(211, 235, 242, 0.3);
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.12);
}

.choices__item.is-selected {
  background: rgba(255, 255, 255, 0.1) !important;
  color: var(--cl-white);
}

.choices__list--dropdown {
  background: var(--cl-dark) !important;
  color: var(--cl-white);
  position: relative !important;
  z-index: 999 !important;

  .choices__item--selectable.is-highlighted {
    background: var(--cl-black) !important;
  }
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  margin-top: -60px;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable[data-select-text],
  .choices__list[aria-expanded] .choices__item--selectable[data-select-text] {
    padding-right: 25px !important;
  }
}
