.btn-green {
  display: grid;
  place-content: center;
  padding: 18px 36px;
  background: var(--cl-green-300);
  color: var(--cl-dark);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  border: 1px solid rgba(240, 251, 245, 0.5);
  border-radius: 100px;
  min-width: 163px;
  height: 48px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: var(--cl-dark);
    transition: all 0.3s ease;
    z-index: -1;
  }

  &:hover {
    color: var(--cl-white);
    border-color: var(--cl-white);

    &::before {
      left: 0;
    }
  }

  &:active {
    transform: scale(0.9);
  }

  &-transparent {
    background: var(--cl-green-50);
    border: 1px solid var(--cl-green-300);

    &:hover {
      border-color: var(--cl-dark);
    }
  }

  &-transparent-second {
    background: transparent;
    color: var(--cl-green-50);
    border: 1px solid var(--cl-green-300);

    &::before {
      background: var(--cl-green-300);
    }

    &:hover {
      background: var(--cl-green-300);   
      color: var(--cl-dark);   
    }
  }
}

.btn-dashed-green {
  display: grid;
  place-content: center;
  padding: 18px 36px;
  background: transparent;
  color: var(--cl-white);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  border: 2px dashed var(--cl-green-300);
  border-radius: 100px;
  width: 159px;
  height: 48px;
  transition: all 0.3s ease;

  .upload-text {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    color: var(--cl-green-300);
    border-color: var(--cl-white);
  }
}

.btn-bordered-green {
  width: 48px;
  height: 48px;
  display: grid;
  place-content: center;
  border-radius: 8px;
  border: 2px solid var(--cl-green-300);
  background: var(--cl-green-50);
  color: var(--cl-green-300);
  font-size: 14px;
}

.btn-search {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: var(--cl-green-50);
  color: var(--cl-dark);
  text-align: center;
  padding: 18px 36px;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: var(--font-main);
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.15px;
  border-radius: 1000px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.12);
  min-width: 155px;
  height: 56px;
  transition: all 0.3s ease;

  i {
    font-size: 16px;
    transition: transform 0.3s ease;
  }

  &:hover {
    background: transparent;
    color: #fff;
    border-color: #fff;

    i {
      transform: rotate(90deg);
    }
  }
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: ac(15px, 10px);
  position: relative;

  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: var(--cl-dark);
    border: 2px solid var(--cl-green-300);
    border-radius: 100px;
    transition: 0.3s;

    &::after {
      display: none;
    }

    &::before {
      content: '\e903';
      display: block;
      font-family: 'icomoon' !important;
    }

    &.swiper-button-prev {
      &::before {
        content: '\e901';
      }
    }

    &:hover {
      background: var(--cl-green-300);
      color: var(--cl-white);
    }

    &.swiper-button-lock {
      display: none;
    }
  }
}

.pagination {
  position: relative;
  display: block;
  width: 100%;

  &__list {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 10px;

    @media (max-width: 480px) {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  &__num,
  &__arrow,
  &__text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    font-size: 20px;
    line-height: 1;
    color: var(--cl-primary);
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid transparent;
    user-select: none;
    cursor: pointer;
    @mixin transition-all;

    @media (min-width: 1600px) {
      width: 60px;
      height: 60px;
      font-size: 20px;
    }

    @media (max-width: 768px) {
      width: 42px;
      height: 42px;
      font-size: 20px;
    }

    @media (max-width: 480px) {
      width: 37px;
      height: 37px;
      font-size: 18px;
    }

    @media (max-width: 374px) {
      width: 33px;
      height: 33px;
      font-size: 15px;
    }
  }

  &__text {
    width: fit-content;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media (max-width: 480px) {
      padding-left: 8px;
      padding-right: 8px;
    }

    @media (max-width: 374px) {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  &__num:hover,
  &__text:hover,
  &__num_active,
  &__arrow:hover {
    color: var(--cl-black);
    background-color: var(--cl-white);
    border-color: var(--cl-green-300);
  }

  &__num_dott {
    pointer-events: none;
    opacity: 0.6;

  }

  &__arrow {
    font-size: 20px;

    @media (max-width: 768px) {
      font-size: 18px;
    }

    @media (max-width: 480px) {
      font-size: 16px;
    }

    @media (max-width: 374px) {
      font-size: 13px;
    }
  }

  &__arrow:active,
  &__num:active {
    transform: translateY(5px);
  }

  .pagination-btn-disabled .pagination__arrow {
    cursor: not-allowed;
    opacity: .5;
    &:hover {
      color: var(--cl-primary);
      background-color: transparent;
      border: 1px solid transparent;
    }
  }
}