.section-post-details {
  position: relative;
  padding: ac(180px, 150px) 0 ac(80px, 30px);
  background: var(--cl-white);

  &__date {
    color: var(--cl-green-500);
    margin-bottom: ac(24px, 18px);
  }

  &__title {
    text-transform: uppercase;
    color: var(--cl-black);
  }

  &__img {
    position: relative;
    width: 100%;
    z-index: 1;
    border-radius: 32px;
    overflow: hidden;

    img {
      border: 1px solid #e4d4cc;
      border-radius: 32px;
      width: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: rgba(43, 51, 69, 0.2);
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(97, 209, 148, 0) 50%,
        rgba(97, 209, 148, 0.5) 100%
      );
      z-index: 2;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: ac(24px, 20px);
    margin-top: ac(48px, 24px);

    .btn-green {
      white-space: nowrap;
      
      &:first-child {
        width: 173px;
      }

      &:last-child {
        width: 221px;
      }
    }

    @media (max-width: 551px) {
      flex-direction: column;

      & > * {
        width: 100% !important;
      }
    }
  }

  &__wr {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: ac(92px, 35px);
    margin-top: ac(48px, 24px);

    @media (max-width: 1024px) {
      gap: ac(40px, 35px);
    }

    @media (max-width: 1023px) {
      flex-direction: column-reverse;
    }
  }

  &-left {
    flex: 1;
    max-width: 67.58%;

    @media (max-width: 1023px) {
      max-width: 100%;
    }
  }

  &-right {
    position: sticky;
    top: 95px;
    flex: 1;
    max-width: 25.45%;
    display: flex;
    flex-direction: column;
    gap: ac(32px, 24px);
    height: 100%;

    @media (max-width: 1023px) {
      max-width: 100%;
    }

    &-back {
      a {
        display: inline-flex;
        align-items: center;
        gap: 12px;
        transition: 0.3s;

        &:hover {
          span {
            color: var(--cl-green-300);
          }

          i {
            color: var(--cl-dark);
          }
        }

        .btn-bordered-green {
          i {
            transition: 0.3s;
          }
        }

        span {
          color: var(--cl-black);
          font-feature-settings: 'liga' off, 'clig' off;
          font-family: var(--font-main);
          font-size: ac(16px, 14px);
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.5px;
          transition: 0.3s;
        }
      }
    }

    &-socials {
      display: flex;
      align-items: center;
      gap: ac(25px, 20px);

      &__text {
        color: var(--cl-black);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: var(--font-main);
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
      }

      &__links {
        display: flex;
        align-items: center;
        gap: ac(22px, 18px);

        @media (max-width: 1024px) {
          gap: 14px;
        }
  
        @media (max-width: 1023px) {
          gap: 18px;
        }

        a {
          transition: 0.3s;
          color: var(--cl-green-500);
          font-size: ac(20px, 16px);
          transition: 0.3s;

          &:hover {
            transform: scale(1.2);
          }
        }

        svg {
          width: ac(20px, 16px);
          height: ac(20px, 16px);

          path {
           width: 100%;
           height: 100%;
          }
        }
      }
    }
  }

  .content-element {
    margin-top: ac(32px, 18px);
    width: 100%;
    color: var(--cl-black);

    @media (max-width: 1023px) {
      max-width: 100%;
    }

    /* p:first-child,
    span:first-child {
      font-family: var(--font-main);
      font-size: ac(28px, 24px);
      font-weight: 300;
      line-height: 1.36;
    } */

    p + h3,
    span + h3 {
      margin-top: ac(37px, 18px);
    }

    h3 + p,
    h3 + span {
      margin-top: ac(25px, 18px);
    }

    ul,
    ol {
      list-style: none;

      li {
        position: relative;
        padding-left: 38px;

        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16' fill='none'%3E%3Cpath d='M2 14L8 8L2 2' stroke='%2361D194' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          left: 12px;
          top: ac(5px, 3px);
        }

        h4 {
          margin-bottom: 12px;
        }
      }
    }

    h3 {
      font-family: var(--font-main);
      font-size: ac(28px, 22px);
      font-weight: 500;
      line-height: 1.36;

      .headline-number {
        position: relative;
        display: inline-block;
        margin-right: 16px;
        width: ac(48px, 36px);
        height: ac(48px, 36px);
        border-radius: 1100px;
        border: 1px solid var(--cl-green-300);
        background: var(--cl-green-50);

        text-align: center;
        vertical-align: middle;

        span {
          color: var(--cl-grey-i);
          font-family: var(--font-main);
          font-size: ac(18px, 14px);
          font-weight: 500;
          line-height: 1.36;
          letter-spacing: 0.045px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    h4 {
      font-family: var(--font-main);
      font-size: ac(24px, 19px);
      font-style: normal;
      font-weight: 500;
      line-height: 1.36;
      letter-spacing: 0.05px;
    }

    ul,
    ol,
    p,
    li {
      font-size: ac(20px, 16px);
      line-height: 1.5;

      &:not(:last-child) {
        margin-bottom: ac(20px, 14px);
      }
    }
  }
}
