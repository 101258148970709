.section-difference {
  position: relative;
  overflow: hidden;
  padding: ac(64px, 32px) 0;
  background: var(--cl-green-50);

  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__wr {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: ac(84px, 24px);

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &-left {
    flex: 1;
    max-width: 36.05%;
    width: 100%;

    @media (max-width: 768px) {
      max-width: 100%;
    }

    &__title {
      
    }
  }

  &-right {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 24px;

    &__subtitle {
      color: var(--cl-green-500);
    }

    &__descr {
      text-align: justify;
    }
  }
}