.section-contact {
  position: relative;
  /* overflow: hidden; */
  padding: ac(80px, 56px) 0 ac(80px, 113px);
  background: var(--cl-dark);

  &.subscribe {
    .section-contact {
      &__wr {
        max-width: 627px;

        .section-hero__form {
          margin-top: 8px;
          display: flex;
          align-items: center;
          gap: ac(8px, 15px);

          .input-search {
            max-width: 419px;
            width: 100%;
            border: 1px solid var(--cl-green-300);
            background: var(--cl-black);
          }

          .btn-search {
            border: 1px solid rgba(240, 251, 245, 0.5);
            background: var(--cl-green-300);

            &:hover {
              background: var(--cl-dark);

              i {
                transform: rotate(45deg);
              }
            }

            @media (max-width: 551px) {
              width: 100%;
            }
          }

          @media (max-width: 551px) {
            flex-direction: column;
            align-items: stretch;
          }
        }
      }

      &__descr {
        font-size: 20px;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(43, 51, 69, 0.8) 0%,
      rgba(43, 51, 69, 0.4) 100%
    );
    z-index: 1;
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wr {
    position: relative;
    z-index: 1;
    max-width: 516px;

    @media (max-width: 551px) {
      max-width: 100%;
    }
  }

  &__title {
    color: var(--cl-green-300);
    margin-bottom: ac(20px, 8px);
  }

  &__descr {
    color: var(--cl-light-blue-i);
    margin-bottom: ac(40px, 32px);
    font-size: ac(35px, 26px);

    @media (max-width: 551px) {
      font-size: 22px;
    }

    @media (max-width: 374px) {
      max-width: 95%;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ac(12px, 16px);

    @media (max-width: 551px) {
      flex-direction: column;
      align-items: stretch;
    }

    & > * {
      width: auto;
    }

    .btn-green {
      @media (max-width: 551px) {
        width: 100%;
      }
    }

    .btn-dashed-green {
      @media (max-width: 551px) {
        width: 100%;
      }
    }
  }
}
