:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);
    width: calc(100% + 10px);
    padding-right: 10px;

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @media (max-width: 640px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  &__block {
    &.team-popup {
      background: var(--cl-white);
      max-width: ac(1080px, 900px);
      width: 95%;
      border-radius: ac(20px, 16px);
      border: ac(24px, 6px) solid var(--cl-white);
      overflow: hidden;
      padding: 1px;

      .team-popup {
        &__container {
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          border-radius: ac(21px, 17px);
          border: 1px solid rgba(8, 48, 77, 0.2);
          overflow: hidden;
        }

        &__card {
          width: ac(312px, 300px);
          min-width: ac(312px, 300px);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          background: var(--cl-white);
          border-right: 1px solid rgba(8, 48, 77, 0.2);
          padding-left: 10px;
          padding-right: 10px;

          @media (max-width: 640px) {
            display: none;
          }
        }

        &__card-image-container {
          width: 100%;
          max-width: ac(248px, 240px);
          overflow: hidden;
          padding-top: ac(32px, 16px);
          position: relative;
          overflow: hidden;

          .team-card-img {
            width: 100%;
            position: relative;
            z-index: 1;
            overflow: hidden;
            border-radius: 50%;

            &:before {
              padding-top: 100%;
            }
          }
        }

        &__card-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          padding: ac(24px, 16px) ac(32px, 20px) ac(29px, 25px);
          background: white;
          flex: 1;

          .team-card-content__name {
            font-size: ac(28px, 22px);
            font-weight: 600;
          }

          .team-card-content__position {
            font-weight: 500;
            font-size: ac(20px, 18px);
            line-height: 1.36;
            color: #4d8739;
            text-align: center;
          }

          .team-card-content__socials {
            &:not(:last-child) {
              margin-bottom: ac(32px, 24px);
            }
          }

          .team-card-content__buttons {
            /* margin-top: auto; */

            .btn-green.btn-green-transparent {
              font-weight: 600;
              &:not(:hover) {
                border-color: rgba(43, 51, 69, 0.5);
                background-color: var(--cl-white);
              }
            }
          }
        }

        &__card-title {
          font-size: ac(32px, 24px);
          font-weight: 600;
          line-height: 1.12;
          letter-spacing: 0.02em;
          color: var(--cl-black);

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        &__card-subtitle {
          font-size: ac(17px, 16px);
          font-weight: 600;
          line-height: 1.44;
          color: #9378c8;

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }

        &__card-socials {
          margin-top: auto;
          grid-column-gap: ac(8px, 6px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: calc(100% - 158px);
        }

        &__card-social {
          font-size: ac(40px, 36px);
          color: var(--cl-black);
          transition: 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            color: var(--cl-purple);
          }
        }

        &__mob-buttons {
          display: none;
        }

        &__content-container {
          width: calc(100% - ac(312px, 300px));
          position: relative;
          background: #f0fbf5;
          padding-right: ac(80px, 40px);
          padding-top: ac(32px, 24px);
          padding-left: ac(40px, 20px);
          padding-bottom: 0;

          @media (min-width: 641px) {
            .team-popup__card-image-container {
              display: none;
            }

            .team-popup__card-content {
              display: none;
            }
          }

          @media (max-width: 640px) {
            width: 100%;

            .team-popup__mob-buttons {
              display: flex;
              margin-bottom: 25px;

              .btn-green.btn-green-transparent {
                &:not(:hover) {
                  border-color: rgba(43, 51, 69, 0.5);
                  background-color: var(--cl-white);
                }
              }
            }

            .team-popup__card-image-container {
              max-width: 290px;
              width: calc(100% - 24px);
              background: transparent;
            }

            .team-popup__card-content {
              padding-left: 0;
              padding-right: 0;
              background: transparent;
              align-items: flex-start;
            }
          }
        }

        &__content {
          width: calc(100% + ac(40px, 21px));
          padding-bottom: ac(50px, 25px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-right: ac(40px, 21px);
          overflow: visible;

          .simplebar-track.simplebar-vertical {
            width: 3px;
            background: #d3ebf2;
            border-radius: 4px;
            transform: none;
            top: ac(68px, 60px);
            bottom: ac(40px, 20px);
            overflow: visible;

            .simplebar-scrollbar {
              background: #61d194;
              border-radius: 4px;
              width: 6px;
              left: -1.5px;

              &:before {
                content: none;
              }
            }
          }

          &:after {
            position: absolute;
            left: 1px;
            right: ac(46px, 24px);
            left: ac(-40px, -20px);
            bottom: 0;
            height: ac(25px, 12px);
            background: linear-gradient(
              180deg,
              rgba(246, 249, 245, 0) 0%,
              rgba(246, 249, 245, 0.8) 27.72%,
              #f6f9f5 83.97%
            );
            pointer-events: none;
            z-index: 1;
            content: '';
          }

          @media (min-width: 641px) {
            max-height: 58vh;
          }

          @media (max-width: 640px) {
            &:after {
              left: -1px;
            }
          }
        }

        &__title {
          color: var(--cl-black);

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }

        &__text {
          width: 100%;
          font-family: var(--font-main);
          font-weight: 400;
          font-size: ac(20px, 16px);
          line-height: 1.5;
          font-family: var(--font-fourth);
          color: var(--cl-black);

          p,
          li {
            font-family: var(--font-main);
            font-weight: 400;
            font-size: ac(20px, 16px);
            line-height: 1.5;
            letter-spacing: 0.0025em;
          }

          h4 {
            padding-top: 8px;
            font-family: var(--font-main);
            font-weight: 500;
            font-size: ac(26px, 20px);
            line-height: 1.36;

            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }

          p,
          ul,
          ol {
            &:not(:last-child) {
              margin-bottom: ac(18px, 14px);
            }

            &:last-child {
              padding-bottom: ac(53px, 25px);
            }
          }
        }

        &__btn-close {
          position: absolute;
          top: ac(20px, 10px);
          right: ac(20px, 10px);
          display: flex;
          justify-content: center;
          align-items: center;
          --size: ac(44px, 28px);
          width: var(--size);
          height: var(--size);
          border-radius: 50%;
          background: var(--cl-green-300);
          cursor: pointer;
          z-index: 10;
          transition: 0.3s;

          svg {
            path {
              transition: 0.3s;
            }
          }

          &:hover {
            background: var(--cl-black);
            svg {
              path {
                fill: var(--cl-white);
              }
            }
          }
        }
      }
    }
  }

  &__block {
    &.sector-popup {
      max-width: 1080px;
      background: #f0fbf5;
      padding: 0;
      border-radius: 20px;

      .team-popup__btn-close {
        position: absolute;
        top: ac(20px, 7px, 420);
        right: ac(20px, -2px, 420);
        display: flex;
        justify-content: center;
        align-items: center;
        --size: ac(44px, 28px);
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background: var(--cl-green-300);
        cursor: pointer;
        z-index: 10;
        transition: 0.3s;

        svg {
          path {
            transition: 0.3s;
          }
        }

        &:hover {
          background: var(--cl-black);
          svg {
            path {
              fill: var(--cl-white);
            }
          }
        }
      }

      .popup-top {
        width: calc(100% + 10px);
        padding: ac(32px, 20px) ac(48px, 32px);
        background: #f0fbf5;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      h3.popup__title {
        color: var(--cl-black);
        font-family: var(--font-main);
        font-size: ac(44px, 25px);
        font-weight: 500;
        line-height: 1.36;
        letter-spacing: -0.22px;
        text-transform: none;
        margin-bottom: ac(16px, 10px);
      }

      h4.popup__title {
        color: var(--cl-green-500);
        font-family: var(--font-main);
        font-size: ac(26px, 16px);
        font-weight: 400;
        line-height: 1.36;
        text-transform: none;
        margin-bottom: 0;
      }

      .popup__content {
        padding: ac(40px, 30px) ac(56px, 36px);
        background: var(--cl-dark);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        .content-element {
          padding-right: ac(40px, 20px);

          ul,
          ol {
            list-style: none;
            margin: ac(28px, 22px) 0 ac(40px, 25px);

            li {
              position: relative;
              padding-left: 25px;
              color: var(--cl-light-blue-i);
              font-size: ac(26px, 22px);
              font-weight: 400;

              &::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' viewBox='0 0 10 16' fill='none'%3E%3Cpath d='M2 14L8 8L2 2' stroke='%2361D194' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: 0;
                top: ac(10px, 8px);
              }

              &:not(:last-child) {
                margin-bottom: ac(16px, 14px);
              }

              h4 {
                margin-bottom: 12px;
              }
            }
          }

          h3 {
            color: var(--cl-green-50);
            font-family: var(--font-main);
            font-size: ac(26px, 20px);
            font-weight: 500;
            line-height: 1.36;
            letter-spacing: -0.051px;

            &:first-of-type {
              font-size: ac(34px, 24px);
            }
          }

          p {
            color: var(--cl-light-blue-i);
            font-family: var(--font-main);
            font-size: ac(20px, 16px);
            font-weight: 350;
            line-height: 1.5;

            &:first-of-type {
              font-size: ac(24px, 18px);
              line-height: 1.36;
            }
          }

          h3 + p {
            margin-top: ac(16px, 10px);
          }

          .img-gradient {
            position: relative;
            z-index: 0;
            border-radius: 32px;

            @media (max-width: 551px) {
              border-radius: 10px;
            }

            &::before {
              content: '';
              position: absolute;
              inset: 0;
              z-index: 2;
              background: #2b334533;
              width: 100%;
              height: 100%;
              border-radius: 32px;

              @media (max-width: 551px) {
                border-radius: 10px;
              }
            }

            &::after {
              content: '';
              position: absolute;
              inset: 0;
              z-index: 2;
              background: linear-gradient(#61d19400 50%, #61d19480 100%);
              width: 100%;
              height: 100%;
              border-radius: 32px;

              @media (max-width: 551px) {
                border-radius: 10px;
              }
            }
          }

          img {
            border-radius: 32px;
            margin: ac(32px, 20px) 0;
            z-index: 0;

            @media (max-width: 551px) {
              border-radius: 10px;
            }
          }
        }

        .simplebar-track.simplebar-vertical {
          width: 6px;
          background: var(--cl-black);
          border-radius: 4px;
          right: ac(45px, 25px);
          top: ac(40px, 20px);
          bottom: ac(56px, 32px);

          .simplebar-scrollbar {
            background: var(--cl-green-300);
            border-radius: 4px;

            &:before {
              content: none;
            }
          }
        }

        .sector-buttons {
          display: flex;
          align-items: center;
          gap: ac(16px, 8px);
          margin-top: ac(32px, 20px);
          padding-right: ac(40px, 20px);
          @media (max-width: 551px) {
            flex-direction: column;
            align-items: stretch;
            button {
              width: 100%;
            }
            & > * {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__block {
    &.case-popup {
      max-width: 1080px;
      background: var(--cl-white);
      padding: 0;
      border-radius: 20px;

      .team-popup__btn-close {
        position: absolute;
        top: ac(20px, 10px);
        right: ac(20px, 10px);
        display: flex;
        justify-content: center;
        align-items: center;
        --size: ac(44px, 28px);
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background: var(--cl-green-300);
        cursor: pointer;
        z-index: 10;
        transition: 0.3s;

        svg {
          path {
            transition: 0.3s;
          }
        }

        &:hover {
          background: var(--cl-black);
          svg {
            path {
              fill: var(--cl-white);
            }
          }
        }
      }

      h3.popup__title {
        color: var(--cl-black);
        font-family: var(--font-main);
        font-size: ac(40px, 32px);
        font-weight: 500;
        line-height: 1.36;
        letter-spacing: -0.06px;
        margin-bottom: ac(16px, 10px);
        text-transform: none;
      }

      h4.popup__title {
        color: var(--cl-green-500);
        font-family: var(--font-main);
        font-size: ac(22px, 18px);
        font-weight: 500;
        line-height: 1.36;
        letter-spacing: 0.055px;
        text-transform: none;
        margin-bottom: 0;
      }

      .popup__top {
        display: flex;
        align-items: stretch;
        max-height: 160px;

        @media (max-width: 551px) {
          flex-direction: column;
          max-height: fit-content;
        }

        &-left {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ac(40px, 35px);
          border-right: 1px solid var(--cl-grey-ii);

          @media (max-width: 551px) {
            padding: 20px;
            justify-content: flex-start;
          }

          img {
            width: ac(200px, 180px);
          }
        }

        &-right {
          padding: ac(32px, 25px) ac(48px, 32px);

          @media (max-width: 551px) {
            padding: 20px 32px;
          }
        }
      }

      .popup__content {
        padding: ac(32px, 24px) ac(84px, 56px) ac(32px, 24px) ac(48px, 32px);
        border: 1px solid var(--cl-grey-ii);
        background: var(--cl-green-50);
        max-height: calc(var(--vh, 1vh) * 46);
        width: 100%;

        .simplebar-track.simplebar-vertical {
          width: 3px;
          background: #d3ebf2;
          border-radius: 4px;
          transform: none;
          top: ac(32px, 24px);
          bottom: ac(32px, 24px);
          overflow: visible;
          right: ac(43px, 36px);

          @media (max-width: 551px) {
            right: 24px;
          }

          .simplebar-scrollbar {
            background: #61d194;
            border-radius: 4px;
            width: 6px;
            left: -1.5px;

            &:before {
              content: none;
            }
          }
        }

        .content-element {
          h3 {
            color: var(--cl-black);
            font-family: var(--font-main);
            font-size: ac(26px, 22px);
            font-weight: 500;
            line-height: 1.36;
            letter-spacing: -0.051px;

            &:first-of-type {
              font-size: ac(34px, 24px);
            }
          }

          p {
            color: var(--cl-black);
            font-family: var(--font-main);
            font-size: ac(20px, 16px);
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.05px;
          }

          h3 + * {
            margin-top: ac(16px, 10px);
          }

          * + h3 {
            margin-top: ac(24px, 18px);
          }
        }
      }

      .popup__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ac(32px, 24px) ac(40px, 32px);

        @media (max-width: 551px) {
          flex-direction: column;
          align-items: stretch;
          gap: 30px;
        }

        &-left {
          display: flex;
          align-items: center;
          gap: ac(20px, 16px);

          &__text {
            color: var(--cl-black);
            font-family: var(--font-main);
            font-size: ac(20px, 18px);
            font-weight: 500;
            line-height: 1.36;
            letter-spacing: 0.05px;
          }

          @media (max-width: 551px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        &-right {
          display: flex;
          align-items: center;
          gap: ac(32px, 24px);

          @media (max-width: 551px) {
            justify-content: center;
          }

          &-btn {
            display: flex;
            align-items: center;
            gap: ac(15px, 12px);
            transition: 0.3s;

            span {
              color: var(--cl-black);
              font-family: var(--font-main);
              font-size: ac(18px, 16px);
              font-weight: 500;
              line-height: 1.36;
              letter-spacing: 0.05px;
              transition: 0.3s;
            }

            svg {
              path {
                transition: 0.3s;
              }
            }

            &:hover {
              span {
                color: var(--cl-green-500);
              }

              svg {
                path {
                  fill: var(--cl-green-500);
                }
              }
            }
          }
        }
      }
    }
  }

  &__block {
    &.apply-now-popup {
      .team-popup__btn-close {
        position: absolute;
        top: ac(20px, 10px);
        right: ac(20px, 10px);
        display: flex;
        justify-content: center;
        align-items: center;
        --size: ac(44px, 28px);
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background: var(--cl-green-300);
        cursor: pointer;
        z-index: 10;
        transition: 0.3s;

        svg {
          path {
            transition: 0.3s;
          }
        }

        &:hover {
          background: var(--cl-black);
          svg {
            path {
              fill: var(--cl-white);
            }
          }
        }
      }

      .contact-form-bottom__left {
        .btn-dashed-green {
          padding: 0;
          user-select: none;
          cursor: pointer;
          color: var(--cl-black);

          @media (max-width: 551px) {
            width: 100%;
          }

          input[type='file'] {
            display: none;
          }
        }

        span {
          max-width: 189px;
          opacity: 0.7;
          color: var(--cl-black);
          font-family: var(--font-main);
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: 0.035px;

          @media (max-width: 551px) {
            max-width: 100%;
          }
        }
      }

      .policy-checkbox {
        label {
          color: var(--cl-black);
        }
      }

      .contact-form-bottom__left .btn-dashed-green {
        &:hover {
          border-color: var(--cl-green-300);
          color: var(--cl-green-300);
        }
      }

      .input-form input {
        color: var(--cl-black);
        @mixin transparent-bg-input var(--cl-black);
      }

      .popup__content {
        .popup-form__container {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;

          & > * {
            flex: 1 1 45%;

            label {
              color: var(--cl-black);
            }

            input {
              width: 100%;
            }

            @media (max-width: 551px) {
              flex: 1 1 100%;
            }
          }
        }

        .policy-checkbox {
          label {
            color: var(--cl-black);

            a {
              &:hover {
                color: var(--cl-green-500) !important;
              }
            }
          }
        }
      }
    }
  }

  &__block {
    &.upload-cv-popup {
      .team-popup__btn-close {
        position: absolute;
        top: ac(20px, 10px);
        right: ac(20px, 10px);
        display: flex;
        justify-content: center;
        align-items: center;
        --size: ac(44px, 28px);
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background: var(--cl-green-300);
        cursor: pointer;
        z-index: 10;
        transition: 0.3s;

        svg {
          path {
            transition: 0.3s;
          }
        }

        &:hover {
          background: var(--cl-black);
          svg {
            path {
              fill: var(--cl-white);
            }
          }
        }
      }

      .contact-form-bottom__left {
        .btn-dashed-green {
          user-select: none;
          cursor: pointer;
          color: var(--cl-black);

          &:hover {
            border-color: var(--cl-green-300);
            color: var(--cl-green-300);
          }

          @media (max-width: 551px) {
            width: 100%;
          }

          input[type='file'] {
            display: none;
          }
        }

        span {
          max-width: 189px;
          opacity: 0.7;
          color: var(--cl-black);
          font-family: var(--font-main);
          font-size: 14px;
          font-weight: 400;
          line-height: 1.4;
          letter-spacing: 0.035px;

          @media (max-width: 551px) {
            max-width: 100%;
          }
        }
      }

      .policy-checkbox {
        label {
          color: var(--cl-black);

          a {
            &:hover {
              color: var(--cl-green-500) !important;
            }
          }
        }
      }

      .input-form input {
        color: var(--cl-black);
        @mixin transparent-bg-input var(--cl-black);
      }

      .popup__content {
        .popup-form__container {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;

          & > * {
            flex: 1 1 45%;

            label {
              color: var(--cl-black);
              white-space: nowrap;
            }

            input {
              width: 100%;
            }

            @media (max-width: 551px) {
              flex: 1 1 100%;
            }
          }
        }

        @media (max-width: 374px) {
          overflow-x: hidden;
        }
      }

      @media (max-width: 374px) {
        padding: 20px;
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
