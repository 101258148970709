.section-solutions {
  position: relative;
  overflow: hidden;
  padding-top: ac(80px, 32px);
  background: var(--cl-green-50);

  &__title {
    color: var(--cl-black);
    font-family: var(--font-main);
    font-size: ac(35px, 22px);
    font-style: normal;
    font-weight: 300;
    line-height: 1.36;
    letter-spacing: -0.14px;
    margin-bottom: 32px;
    text-align: justify;
  }

  &__btn {
    @media (max-width: 768px) {
      min-width: 195px;
    }

    @media (max-width: 374px) {
      min-width: 140px; 
      padding: 18px 24px;
    }
  }
}

.solutions-swiper-wr {
  position: relative;
  margin-top: ac(76px, 32px);

  .swiper {
    overflow: visible !important;
  }

  .swiper-slide:first-of-type {
    .solutions-card {
      border-top-left-radius: ac(60px, 24px);
    }
  }

  .swiper-slide:last-of-type {
    .solutions-card {
      border-top-right-radius: ac(60px, 24px);
    }
  }

  .swiper-buttons {
    position: absolute;
    right: 24px;
    top: -22%;
  }

  .solutions-card {
    position: relative;
    height: ac(540px, 374px);
    padding: ac(60px, 24px);
    color: var(--cl-white);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;

    @media (max-width: 1024px) {
      padding-left: 14px;
      padding-right: 14px;
    }

    @media (max-width: 768px) {
      padding-left: 24px;
      padding-right: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(43, 51, 69, 0.27) 0%,
        rgba(43, 51, 69, 0.9) 100%
      );
      z-index: 1;
    }

    &::after {
      visibility: hidden;
      opacity: 0;
      content: '';
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(97, 209, 148, 0) 50%,
        rgba(97, 209, 148, 0.5) 100%
      );
      transition: all 0.3s ease;
      z-index: 1;
    }

    &__bg {
      position: absolute;
      inset: 0;
      z-index: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;

      &__arrow {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        font-size: ac(25px, 20px);
      }
    }

    &__descr {
      z-index: 1;
      color: var(--cl-light-blue-i);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &:hover {
      .solutions-card-top__arrow {
        visibility: visible;
        opacity: 1;
      }

      &::after {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
